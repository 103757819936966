import { createReducer } from '@reduxjs/toolkit';
import { fetchPropertySuccess, setLoading, setExclusive, clearProperty, setRequestCallbackModalOpen } from './propertyActions';

const initialState = {
  isLoading: false,
  isRequestCallbackModalOpen: false,
  property: null,
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(fetchPropertySuccess, (state, { payload }) => {
      state.property = payload.property;
    })
    .addCase(setLoading, (state, { payload }) => {
      state.isLoading = payload;
    })

    .addCase(setExclusive, (state, { payload }) => {
      state.isExclusive = payload;
    })

    .addCase(clearProperty, (state) => {
      state.isExclusive = false;
      state.property = null;
    })

    .addCase(setRequestCallbackModalOpen, (state, { payload }) => {
      state.isRequestCallbackModalOpen = payload;
    }),
);
