import { createReducer } from '@reduxjs/toolkit';
import {
  setFullscreenGalleryContent,
  setIsPointsPay,
  setIsQtaClient,
  setIsAnzClient,
  setDateChangeMessaging,
  clearDateChangeMessaging,
  setAppVersion,
  setMapHoveredPropertyId,
  setMapActivePropertyId,
} from './uiActions';

export const initialState = {
  fullscreenGalleryContent: { images: [], startIndex: 0 },
  appOutOfDate: false,
  appVersion: null,
  isPointsPay: false,
  isQtaClient: false,
  isAnzClient: false,
  mapHoveredPropertyId: null,
  mapActivePropertyId: null,
  propertyFiltersModalVisible: false,
  dateChangeMessaging: {
    isVisible: false,
  },
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setFullscreenGalleryContent, (state, { payload }) => {
      state.fullscreenGalleryContent = payload;
    })

    .addCase(setIsPointsPay, (state, { payload }) => {
      state.isPointsPay = payload;
    })

    .addCase(setIsQtaClient, (state, { payload }) => {
      state.isQtaClient = payload;
    })

    .addCase(setIsAnzClient, (state, { payload }) => {
      state.isAnzClient = payload;
    })

    .addCase(setDateChangeMessaging, (state, { payload }) => {
      state.dateChangeMessaging = {
        checkOut: payload.checkOut,
        checkIn: payload.checkIn,
        isVisible: true,
      };
    })

    .addCase(clearDateChangeMessaging, (state) => {
      state.dateChangeMessaging = {
        isVisible: false,
      };
    })

    .addCase(setMapHoveredPropertyId, (state, { payload }) => {
      state.mapHoveredPropertyId = payload;
    })

    .addCase(setMapActivePropertyId, (state, { payload }) => {
      state.mapActivePropertyId = payload;
    })

    .addCase(setAppVersion, (state, { payload }) => {
      state.appOutOfDate = payload.appOutOfDate;
      state.appVersion = payload.appVersion;
    }),
);
