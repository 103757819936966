import { Box, Flex } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';

export const TooltipWrapper = styled(Flex)`
  z-index: ${themeGet('zIndices.stickyNavigation')};
`;

export const ArrowUp = styled(Box)`
  z-index: ${themeGet('zIndices.loginToolTip')};
  margin-bottom: -1px;
  align-self: flex-end;
  margin-right: ${themeGet('space.8')};
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid ${themeGet('colors.white')};

  ${themeGet('mediaQueries.1')} {
    border-bottom: 10px solid ${themeGet('colors.snow')};
  }
`;

export const ArrowRight = styled(Box)`
  align-self: center;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid ${themeGet('colors.white')};
`;

export const Card = styled(Flex)`
  z-index: ${themeGet('zIndices.stickyCampaignMessaging')};
  height: 74px;
  padding: ${themeGet('space.4')};
  background: linear-gradient(-180deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 35%, #E6E6E6 100%);
  border-top: 1px solid ${themeGet('colors.greys.alto')};
  border-bottom: 1px solid ${themeGet('colors.greys.alto')};
  box-shadow: ${themeGet('shadows.hover')};

  ${themeGet('mediaQueries.1')} {
    border-top: 0px;
    border-radius: 0 0 0 ${themeGet('radii.default')};
  }
}
`;
