import cookies from 'js-cookie';
import { setAuthentication, trackAuthentication, setAuthenticationCompleted, clearAuthentication } from 'store/user/userActions';
import fetchMemberDetails from 'lib/clients/fetchMemberDetails';
import { COOKIE_NAMES } from 'lib/enums/cookies';
import { getLoginUrl } from 'lib/qffAuth/getUrls';
import { get } from 'lodash';

const authenticateUserOAuth = async (dispatch, getState: () => unknown) => {
  const uid = cookies?.get(COOKIE_NAMES.QH_AUTH_MEMBER_ID);

  const isProd = process.env.NEXT_PUBLIC_ENVIRONMENT === 'production';
  const hasQLSessCookie = typeof cookies?.get(COOKIE_NAMES.QL_WL_SESSION) === 'string';

  if (isProd && !hasQLSessCookie) {
    dispatch(clearAuthentication());
  } else if (typeof uid !== 'string') {
    if (isProd && hasQLSessCookie) {
      const loginUrl = getLoginUrl();
      if (typeof loginUrl === 'string') {
        window.location.href = loginUrl + '&prompt=none';
        return;
      }
    }
    dispatch(clearAuthentication());
  } else {
    try {
      const memberDetails = await fetchMemberDetails({ memberId: uid });
      if (typeof get(getState(), 'user.authentication.memberId') !== 'string') dispatch(trackAuthentication({ memberId: uid }));
      dispatch(setAuthentication({ memberId: uid, ...memberDetails }));
    } catch (e) {
      dispatch(clearAuthentication());
      if (e?.response?.status === 401) {
        const loginUrl = getLoginUrl();
        if (isProd && typeof loginUrl === 'string') {
          window.location.href = loginUrl + '&prompt=none';
          return;
        }
      }
    }
  }

  dispatch(setAuthenticationCompleted());
};

export default authenticateUserOAuth;
