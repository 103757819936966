import Visibility from 'lib/browser/visibility';
import { fetchTreatments, setIsVip } from 'store/split/splitActions';
import { getSplitAttributes } from 'store/split/splitSelectors';
import { SPLIT_BROWSER_REFRESH_RATE_SECS } from 'config';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import cookies from 'js-cookie';
import { COOKIE_NAMES } from 'lib/enums/cookies';

const DEFAULT_REFRESH_RATE = 30;
const REFRESH_RATE = parseInt(SPLIT_BROWSER_REFRESH_RATE_SECS!) || DEFAULT_REFRESH_RATE;

export const useSplit = () => {
  const dispatch = useDispatch();
  const attributes = useSelector(getSplitAttributes);

  useMount(() => {
    if (!attributes.isVip && cookies.get(COOKIE_NAMES.SPLIT_VIP)) {
      dispatch(setIsVip(true));
      dispatch(fetchTreatments());
    }
    Visibility.every(REFRESH_RATE * 1000, () => {
      dispatch(fetchTreatments());
    });
  });
};

export const initializeSplit = (store) => {
  Visibility.every(REFRESH_RATE * 1000, () => {
    store.dispatch(fetchTreatments());
  });
};
