import * as actions from './calendarActions';
import * as errorActions from 'store/error/errorActions';
import * as client from 'lib/clients/fetchCalendar';
import { createAsyncLogic } from 'lib/logic';

export const fetchCalendarLogic = createAsyncLogic({
  type: actions.fetchCalendar,
  latest: true,
  async process({ action }, dispatch) {
    dispatch(errorActions.clearErrors());

    const { payload } = action;
    const calendar = await client.fetchCalendar(payload);
    dispatch(actions.setCalendar(calendar));
  },
  onError({ parsedError }, dispatch) {
    dispatch(errorActions.apiRequestFailure(parsedError));
  },
});

export const fetchCalendarByOfferLogic = createAsyncLogic({
  type: actions.fetchCalendarByOffer,
  latest: true,
  async process({ action }, dispatch) {
    dispatch(errorActions.clearErrors());

    const { payload } = action;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const calendar = await client.fetchCalendarByOffer(payload);
    dispatch(actions.setCalendar(calendar));
  },
  onError({ parsedError }, dispatch) {
    dispatch(errorActions.apiRequestFailure(parsedError));
  },
});

export const logic = [fetchCalendarLogic, fetchCalendarByOfferLogic];
