import wrappedAxios from './wrappedAxios';
import { HOTELS_API_URL } from 'config';

type Props = { cookie?: string | Record<string, string>; previewToken?: string } & Record<string, unknown>;

export const fetchContent = async ({ cookie, previewToken, ...rest }: Props = {}) => {
  const endpoint = `content${previewToken ? '/preview' : ''}`;

  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/${endpoint}`,
    method: 'GET',
    params: { ...rest },
    cookie,
    ...(previewToken && {
      headers: {
        'x-sanity-api-key': previewToken,
      },
    }),
  });

  return response.data;
};
