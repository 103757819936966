import { createAction } from '@reduxjs/toolkit';

export const clearHotelsAndAirbnbTermsAndConditions = createAction(
  'hotelsAndAirbnbTermsAndConditions/CLEAR_HOTELS_AND_AIRBNB_TERMS_AND_CONDITIONS',
);
export const fetchHotelsAndAirbnbTermsAndConditions = createAction(
  'hotelsAndAirbnbTermsAndConditions/FETCH_HOTELS_AND_AIRBNB_TERMS_AND_CONDITIONS',
);
export const setHotelsAndAirbnbTermsAndConditions = createAction(
  'hotelsAndAirbnbTermsAndConditions/SET_HOTELS_AND_AIRBNB_TERMS_AND_CONDITIONS',
);
