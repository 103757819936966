import { theme as baseTheme } from '@qga/roo-ui';
import merge from 'lodash/merge';

import * as icons from './icons';
import { zIndices } from './zIndices';
import { colors } from './colors';
import { card } from './card';
import { fontSizes } from './fontSizes';
import { shadows } from './shadows';
import { radii } from './radii';
import { slider } from './slider';
import { textStyles } from './textStyles';
import { buttons } from './buttons';
import { boxWidths, maxWidths, breakpoints, mediaQueries, uiStructure, imageSizes } from './sizing';
import { focus } from './focus';

// based on qantas theme - https://github.com/hooroo/roo-ui/blob/master/src/theme.js

const theme = merge({}, baseTheme, {
  boxWidths,
  maxWidths,
  fontSizes,
  focus,
  breakpoints,
  mediaQueries,
  shadows,
  uiStructure,
  zIndices,
  radii,
  imageSizes,
  colors,
  icons,
  slider,
  textStyles,
  buttons,
  card,
});

export default theme;
