import { zIndices } from './zIndices';
import { theme as baseTheme } from '@qga/roo-ui';

export const card = {
  inline: {
    badge: {
      zIndex: zIndices.dropdownContent,
    },
    defaultImage: {
      width: 135,
    },
    text: {
      padding: '1rem',
    },
  },
  stacked: {
    badge: {
      zIndex: zIndices.dropdownContent,
    },
  },
  propertyCard: {
    propertyName: {
      color: baseTheme.colors.greys.charcoal,
      fontSize: baseTheme.fontSizes.md,
      marginBottom: 0,
    },
  },
  searchResultCard: {
    sashContainer: {
      position: 'absolute',
      top: 0,
      right: baseTheme.space[3],
      marginTop: baseTheme.space[1],
    },
  },
};
