import React from 'react';

import { Box } from '@qga/roo-ui/components';
import Footer from 'components/Footer';
import Header from 'components/Header';
import ErrorBoundary from 'components/errors/ErrorBoundary';

interface LayoutProps {
  children: React.ReactNode;
}

const DefaultLayout = ({ children }: LayoutProps) => {
  return (
    <>
      <Box flex="1 0 auto" width="100%" bg="greys.porcelain">
        <Header />
        <ErrorBoundary>{children}</ErrorBoundary>
      </Box>
      <Footer />
    </>
  );
};

export default DefaultLayout;
