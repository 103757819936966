import Decimal from 'decimal.js';
import mapValues from 'lodash/mapValues';
import isNumber from 'lodash/isNumber';
import { createSelector } from '@reduxjs/toolkit';

export const getCampaign = (state) => state?.campaign?.campaign;

export const getCampaignName = createSelector(getCampaign, (campaign) => campaign?.name);

export const getCampaignDefaultSash = createSelector(getCampaign, (campaign) => campaign?.defaultSash);

export const getCampaignMessages = createSelector(getCampaign, (campaign) => campaign?.messages);

export const getCampaignTitle = createSelector(getCampaignMessages, (messages) => messages?.title);

export const getCampaignMessage = createSelector(getCampaignMessages, (messages) => messages?.message);

export const getCampaignUrl = createSelector(getCampaignMessages, (messages) => messages?.callToAction?.url);

export const getCampaignUrlLabel = createSelector(getCampaignMessages, (messages) => messages?.callToAction?.label);

export const getCampaignTracking = createSelector(getCampaignMessages, (messages) => messages?.callToAction?.tracking);

export const getCampaignTermsAndConditions = createSelector(getCampaignMessages, (messages) => messages?.termsAndConditions);

export const getCampaignPriceMessages = createSelector(
  getCampaignMessages,
  (messages) => messages?.priceMessages ?? { points: null, cash: null },
);

export const getPointsStrikethroughMessage = createSelector(getCampaignMessages, (messages) => messages?.pointsStrikethroughMessage);

export const getPointsStrikethroughSavings = createSelector(getCampaignMessages, (messages) => messages?.pointsStrikethroughSavings);

export const getPayWithToggleMessage = createSelector(getCampaignMessages, (messages) => messages?.payWithToggleMessage);

export const getPayWithCheckoutMessage = createSelector(getCampaignMessages, (messages) => messages?.payWithCheckoutMessage);

export const getMillionPointsMessage = createSelector(getCampaignMessages, (messages) => messages?.millionPointsMessage);

export const getCampaignCountries = createSelector(getCampaign, (campaign) => campaign?.countries);

export const getCampaignOriginalTiers = createSelector(getCampaign, (campaign) => {
  const tiers = campaign?.originalTier?.tiers || [];
  const parsedTiers = tiers.map((tier) => mapValues(tier, (numValue) => isNumber(numValue) && new Decimal(numValue)));
  return parsedTiers;
});
