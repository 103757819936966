import { createReducer } from '@reduxjs/toolkit';
import { clearErrors, apiRequestFailure } from './errorActions';
import { ParsedHttpError } from 'types/error';

const initialState: { apiError: ParsedHttpError | null } = {
  apiError: null,
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(clearErrors, (state) => {
      state.apiError = null;
    })
    .addCase(apiRequestFailure, (state, { payload }) => {
      state.apiError = payload;
    }),
);
