import keyBy from 'lodash/keyBy';
import { format, addDays } from 'date-fns';
import { HOTELS_BRAND_NAME } from 'config';

export const SORT_BY = Object.freeze({
  PROMOTION: {
    code: 'promotion',
    name: 'Best Deal',
  },
  PRICE_ASC: {
    code: 'price_asc',
    name: 'Lowest Price',
  },
  PRICE_DESC: {
    code: 'price_desc',
    name: 'Highest Price',
  },
  POPULARITY: {
    code: 'popularity',
    name: 'Popularity',
  },
  TRIPADVISOR: {
    code: 'tripadvisor',
    name: 'TripAdvisor Rating',
  },
});

export const SORTS_BY_CODE = keyBy(
  Object.keys(SORT_BY).map((key) => SORT_BY[key]),
  'code',
);

export const SORT_SELECT_OPTIONS = [
  { label: SORT_BY.PROMOTION.name, value: SORT_BY.PROMOTION.code },
  { label: SORT_BY.POPULARITY.name, value: SORT_BY.POPULARITY.code },
  { label: SORT_BY.PRICE_DESC.name, value: SORT_BY.PRICE_DESC.code },
  { label: SORT_BY.PRICE_ASC.name, value: SORT_BY.PRICE_ASC.code },
  { label: SORT_BY.TRIPADVISOR.name, value: SORT_BY.TRIPADVISOR.code },
];

export const PROPERTY_TYPES = [
  {
    type: 'apartments',
    name: 'Apartments',
  },
  {
    type: 'backpackers_hostels',
    name: 'Backpackers and Hostels',
  },
  {
    type: 'bed_breakfasts',
    name: 'Bed and Breakfasts',
  },
  {
    type: 'cabins_cottages',
    name: 'Cabins and Cottages',
  },
  {
    type: 'caravans_camping',
    name: 'Caravans and Camping',
  },
  {
    type: 'farm_stays',
    name: 'Farm Stays',
  },
  {
    type: 'holiday_houses',
    name: 'Holiday Houses',
  },
  {
    type: 'hotels',
    name: 'Hotels',
  },
  {
    type: 'motels',
    name: 'Motels',
  },
  {
    type: 'resorts',
    name: 'Resorts',
  },
  {
    type: 'retreats',
    name: 'Retreats',
  },
  {
    type: 'retreats_lodges',
    name: 'Retreats and Lodges',
  },
  {
    type: 'self_contained_apartments',
    name: 'Self Contained Apartments',
  },
  {
    type: 'villas',
    name: 'Villas',
  },
  {
    type: 'wilderness_safari_retreats',
    name: 'Wilderness Safari Retreats',
  },
  {
    type: 'holiday_parks',
    name: 'Holiday Parks',
  },
];

export const PROPERTY_TYPES_BY_TYPE = keyBy(PROPERTY_TYPES, 'type');

export const SEARCH_PAGE_META = [
  { name: 'hotels-booking-stage', content: 'search' },
  { name: 'robots', content: 'noindex, follow' },
  { name: 'description', content: `Search results | ${HOTELS_BRAND_NAME} Australia` },
];

export const getDefaults = () => ({
  location: 'Melbourne, VIC, Australia',
  adults: 2,
  children: 0,
  infants: 0,
  checkIn: format(addDays(new Date(), 14), 'yyyy-MM-dd'),
  checkOut: format(addDays(new Date(), 15), 'yyyy-MM-dd'),
  sortBy: SORT_BY.POPULARITY.code,
});

export const DEFAULT_FILTERS = {
  minPrice: undefined,
  maxPrice: undefined,
  propertyTypes: [],
  minStarRating: undefined,
  minTripadvisorRating: undefined,
  facilities: [],
  freeCancellation: undefined,
  depositPay: undefined,
  classicRewards: undefined,
  subRegions: [],
  luxOfferOnly: undefined,
};

export const PROMO_AREA_STATES = Object.freeze({
  COLLAPSED: 'collapsed',
  EXPANDED: 'expanded',
});
