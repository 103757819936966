import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

export const setZeroTime = (date) => {
  if (date) {
    return new Date(date).setHours(0, 0, 0, 0);
  } else {
    return new Date().setHours(0, 0, 0, 0);
  }
};

export const isDuplicateSearch = (localStoragePayload, newPayload) => {
  const formattedCheckIn = setZeroTime(newPayload['checkIn']);
  const formattedCheckOut = setZeroTime(newPayload['checkOut']);
  newPayload = { ...newPayload, checkIn: formattedCheckIn, checkOut: formattedCheckOut };

  const localStoragePayloadToCompare = pick(localStoragePayload, ['location', 'checkIn', 'checkOut']);
  const newPayloadToCompare = pick(newPayload, ['location', 'checkIn', 'checkOut']);

  return isEqual(localStoragePayloadToCompare, newPayloadToCompare);
};

const isInThePast = (localStoragePayload) => {
  const today = setZeroTime();
  const checkIn = setZeroTime(localStoragePayload.checkIn);
  const checkOut = setZeroTime(localStoragePayload.checkOut);
  const checkInPast = today > checkIn;
  const checkOutPast = today > checkOut;

  return checkInPast || checkOutPast;
};

export const validSearches = (localStoragePayloads) => {
  if (!localStoragePayloads) return;

  localStoragePayloads.forEach((localStoragePayload, index) => {
    if (isInThePast(localStoragePayload)) {
      localStoragePayloads.splice(index, 1);
    }
  });

  return localStoragePayloads;
};
