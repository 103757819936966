import get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
import { getIpAddress, getBrowser, getIsBot, getDeviceType } from 'store/userEnvironment/userEnvironmentSelectors';

export const getTreatments = (state) => get(state, 'split.treatments', {});

//ensure that the same attributes are passed to split in the split proxy app
export const getSplitAttributes = (state) => ({
  ipAddress: getIpAddress(state),
  isVip: get(state, 'split.isVip', false),
  userAgent: getBrowser(state).userAgent,
  isBot: getIsBot(state),
  deviceType: getDeviceType(state),
  systemTime: new Date().getTime(), // Test scheduled splits by returning a specific Unix time. Eg: new Date('2022-10-24').getTime()
});

export const getIsRedemptionAB2New = createSelector(getTreatments, (treatment) => get(treatment, 'redemptions-ab-2.treatment') === 'new');
