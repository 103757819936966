import { createReducer } from '@reduxjs/toolkit';
import { updateFormData, setBookingClientRequestId, setBookingChannel, clearFormData } from './checkoutActions';
import merge from 'lodash/merge';

const initialState = {
  bookingChannel: null,
  bookingClientRequestId: null,
  formData: {
    title: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    subscribe: '',
    specialRequests: '',
    qffNumber: '',
    abn: '',
    paymentMode: null,
    payments: {
      voucher: {
        amount: null,
        code: null,
        pin: null,
      },
      points: {
        amount: null,
        amountInCash: null,
        tierVersionCode: null,
        qffNumber: null,
      },
      travelPass: {
        amount: null,
      },
      cash: {
        payableNow: {
          amount: null,
        },
        payableLater: {
          amount: null,
          date: null,
        },
        creditCard: {
          sessionId: null,
          expiryMonth: null,
          expiryYear: null,
          cardType: null,
          cardNumber: null,
          securityCode: null,
          apiVersion: null,
          merchantId: null,
        },
      },
    },
  },
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(updateFormData, (state, { payload }) => {
      state.formData = merge({}, { ...state.formData }, payload);
    })
    .addCase(setBookingClientRequestId, (state, { payload }) => {
      state.bookingClientRequestId = payload;
    })
    .addCase(setBookingChannel, (state, { payload }) => {
      state.bookingChannel = payload;
    })
    .addCase(clearFormData, (state) => {
      state.formData = initialState.formData;
    }),
);
