import React, { FC, Fragment } from 'react';
import { rem } from 'polished';
import { GlobalHeaderContextType, useGlobalHeaderContext } from '../../GlobalHeaderContext';
import { Box, Flex, Icon, NakedButton, Text } from '@qga/roo-ui/components';
import { QffAuthDetails } from './QffAuthDetails';
import { QffMenuContent } from '../../QffMenuContent';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';

const TAB_HEIGHT = rem(74);
const TAB_AUTHENTICATED_WIDTH = rem(300);
const TAB_UNAUTHENTICATED_WIDTH = rem(92);

export const QffMenuWrapper = styled(Flex)`
  width: 300px;
  z-index: ${themeGet('zIndices.phoneMenuDrawerClose')};
  position: absolute;
  flex-direction: column;
  transition: all 0.2s ease-in-out;
  background-color: ${themeGet('colors.white')};
  box-shadow: ${themeGet('shadows.soft')};
`;

const RedTab = styled(NakedButton)<RedTabProps>`
  font-size: ${themeGet('fontSizes.header.base')};
  cursor: pointer;
  display: flex;
  color: ${themeGet('colors.white')};
  background-color: ${themeGet('colors.brand.primary')};
  height: ${TAB_HEIGHT};
  padding: ${themeGet('space.4')} 0;
  align-items: center;
  justify-content: center;

  ${({ isAuthenticated, isOpen }) => css`
    min-width: ${isAuthenticated ? TAB_AUTHENTICATED_WIDTH : TAB_UNAUTHENTICATED_WIDTH};
    border-radius: ${isOpen ? undefined : '0 0 4px 4px'};
  `}
`;

interface RedTabProps {
  isAuthenticated: boolean;
  isOpen: boolean;
}

const AuthBtnOpen = () => (
  <Flex flexDirection="column" alignItems="center">
    <Icon name="close" data-testid="close-icon" />
    <Text data-testid="close-text">Close</Text>
  </Flex>
);

const AuthBtnClosed = () => (
  <Fragment>
    <Icon name="person" data-testid="person-icon" />
    <QffAuthDetails />
  </Fragment>
);

const handleAuthTabClick = (context: GlobalHeaderContextType) => () => {
  if (context.qffAuthData.isAuthenticated) {
    context.onInteraction({
      type: 'QFF Login Dropdown',
      value: context.isQffMenuOpen ? 'Desktop Dropdown Collapsed' : 'Desktop Dropdown Expanded',
    });

    context.toggleQffMenu();
  } else {
    context.onInteraction({
      type: 'QFF Login Button',
      value: 'Desktop Log In Button Selected',
    });

    context.onClickLogin();
  }
};

export const QffMenu: FC = () => {
  const context = useGlobalHeaderContext();
  const { isAuthenticated } = context.qffAuthData;
  return (
    <Box zIndex={1}>
      <RedTab
        isAuthenticated={isAuthenticated}
        isOpen={context.isQffMenuOpen}
        role="button"
        onClick={handleAuthTabClick(context)}
        data-testid={isAuthenticated ? 'qff-menu-tab' : 'qff-login-tab'}
      >
        {!isAuthenticated && 'Log in'}
        {isAuthenticated && context.isQffMenuOpen && <AuthBtnOpen />}
        {isAuthenticated && !context.isQffMenuOpen && <AuthBtnClosed />}
      </RedTab>

      {isAuthenticated && context.isQffMenuOpen && (
        <QffMenuWrapper>
          <QffMenuContent />
        </QffMenuWrapper>
      )}
    </Box>
  );
};
