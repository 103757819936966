import { createReducer } from '@reduxjs/toolkit';
import { setAirbnbPage } from './airbnbPageActions';

const initialState = {
  airbnbPage: null,
};

export const reducer = createReducer(initialState, (builder) =>
  builder.addCase(setAirbnbPage, (state, { payload }) => {
    state.airbnbPage = payload;
  }),
);
