import { format as formatDate } from 'lib/date';
import { SEARCH_DATE_FORMAT } from 'config';
import { addDays, addMonths, lastDayOfMonth, startOfMonth } from 'date-fns';
/*
 ** Copyright (C) 2018 Bloomberg LP. All rights reserved.
 ** This code is governed by the license found in the LICENSE file.
 */

const daysInMonth = {
  standard: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
  leapyear: [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
};

function getDaysInMonth(year, month) {
  return daysInMonth[isLeapYear(year) ? 'leapyear' : 'standard'][month - 1];
}

export function pad(num, cnt) {
  const padding = number(cnt);
  const str = `${Math.abs(+num)}`;
  const prefix = new Array(padding).fill('0').join('');
  return `${prefix}${`${str}`.trim()}`.slice(-1 * Math.max(padding, str.length));
}

export function signedpad(num, cnt) {
  return `${+num < 0 ? '-' : ''}${pad(num, cnt)}`;
}

export function number(num) {
  if (isNaN(+num)) throw new TypeError(`invalid number ${num}`);
  return +num;
}

export function plus({ year = 0, month = 0, day = 0 }, { years = 0, months = 0, days = 0 }) {
  year += years;
  month += months;
  day += days;

  while (month < 1) {
    month += 12;
    year -= 1;
  }
  while (month > 12) {
    month -= 12;
    year += 1;
  }

  while (day < 1) {
    month -= 1;
    if (month === 0) {
      month = 12;
      year -= 1;
    }
    day += getDaysInMonth(year, month);
    if (month < 1) {
      month = 12;
      year -= 1;
    }
  }
  while (day > getDaysInMonth(year, month)) {
    day -= getDaysInMonth(year, month);
    month += 1;
    if (month > 12) {
      month = 1;
      year += 1;
    }
  }

  while (month < 1) {
    month += 12;
    year -= 1;
  }
  while (month > 12) {
    month -= 12;
    year += 1;
  }

  return {
    year,
    month,
    day,
  };
}

export function isLeapYear(year) {
  const isDiv4 = year % 4 === 0;
  const isDiv100 = year % 100 === 0;
  const isDiv400 = year % 400 === 0;
  return isDiv4 && (!isDiv100 || isDiv400);
}

export function toDayOfWeek(year, month, day) {
  const m = month + (month < 3 ? 10 : -2);
  const Y = year - (month < 3 ? 1 : 0);

  const c = Math.floor(Y / 100);
  const y = Y - c * 100;
  const d = day;

  const pD = d;
  const pM = Math.floor(2.6 * m - 0.2);
  const pY = y + Math.floor(y / 4);
  const pC = Math.floor(c / 4) - 2 * c;

  const dow = (pD + pM + pY + pC) % 7;

  return dow + (dow < 0 ? 7 : 0);
}

export function toDayOfYear(year, month, day) {
  let days = day;
  for (let m = month - 1; m > 0; m--) {
    days += getDaysInMonth(year, m);
  }
  return days;
}

export function toWeekOfYear(year, month, day) {
  let doy = toDayOfYear(year, month, day);
  let dow = toDayOfWeek(year, month, day) || 7;
  let doj = toDayOfWeek(year, 1, 1);

  const week = Math.floor((doy - dow + 10) / 7);

  if (week < 1) {
    if (doj === (isLeapYear(year) ? 5 : 6)) {
      return 53;
    } else {
      return 52;
    }
  }
  if (week === 53) {
    if ((isLeapYear(year) ? 366 : 365) - doy < 4 - dow) {
      return 1;
    }
  }

  return week;
}

export function leapYearsBetween(startYear, endYear) {
  if (startYear === endYear) return 0;
  return leapYearsBefore(endYear) - leapYearsBefore(startYear);
}

export const leapYearsBefore = (year) => {
  year--;
  const leapYears = Math.floor(year / 4);
  const centennialAdjustment = Math.floor(year / 100);
  const centennialSpecialAdjustment = Math.floor(year / 400);
  return leapYears - centennialAdjustment + centennialSpecialAdjustment;
};

export const getFirstCalendarDay = (startDate) => {
  //return either first calendar that is equal or greater than today
  const today = new Date();
  const baseDate = startDate || today;
  const thisMonth = startOfMonth(baseDate);
  const firstDay = thisMonth > today ? thisMonth : today;
  return firstDay ? formatDate(firstDay, SEARCH_DATE_FORMAT) : formatDate(today);
};

export const getLastCalendarDay = (startDate) => {
  const baseDate = startDate || new Date();
  const nextMonth = addMonths(baseDate, 1);
  const lastDay = lastDayOfMonth(nextMonth);

  return formatDate(addDays(lastDay, 1), SEARCH_DATE_FORMAT);
};
