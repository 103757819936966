import { createAction } from '@reduxjs/toolkit';
import { StoreStatus } from 'lib/enums/store';
import { DealType, emitPageResults, PageContent } from 'types/deals';
import { ParsedHttpError } from 'types/error';

export const clearDeals = createAction('deals/CLEAR_DEALS');
export const clearPageContent = createAction('deals/CLEAR_PAGE_CONTENT');

export const fetchDeals = createAction<{ limit: number } | undefined>('deals/FETCH_DEALS');
export const fetchPageContent = createAction('deals/FETCH_PAGE_CONTENT');

export const setDeals = createAction('deals/SET_DEALS', (deals) => ({ payload: deals }));
export const setDealType = createAction<DealType>('deals/SET_DEAL_TYPE');
export const setLocationDeals = createAction('deals/SET_LOCATION_DEALS', (locationDeals) => ({ payload: locationDeals }));
export const setError = createAction<ParsedHttpError>('deals/SET_ERROR');
export const setFilters = createAction('deals/SET_FILTERS', (filters) => ({ payload: filters }));
export const setPageContent = createAction<PageContent>('deals/SET_PAGE_CONTENT');
export const setStatus = createAction<StoreStatus>('deals/SET_STATUS');
export const emitDealsPageResults = createAction<emitPageResults>('deals/EMIT_DEALS_PAGE_RESULTS');
