import * as actions from 'store/hotelsAndAirbnbTermsAndConditions/hotelsAndAirbnbTermsAndConditionsActions';
import * as errorActions from 'store/error/errorActions';
import * as client from 'lib/clients/fetchContent';
import { createAsyncLogic } from 'lib/logic';

export const fetchHotelsAndAirbnbTermsAndConditionsLogic = createAsyncLogic({
  type: actions.fetchHotelsAndAirbnbTermsAndConditions,
  latest: true,
  async process(_, dispatch) {
    const hotelsAndAirbnbTermsAndConditions = await client.fetchContent({ type: 'hotelsAndAirbnbTermsAndConditions' });
    dispatch(actions.setHotelsAndAirbnbTermsAndConditions(hotelsAndAirbnbTermsAndConditions));
  },
  onError({ parsedError }, dispatch) {
    dispatch(errorActions.apiRequestFailure(parsedError));
  },
});
