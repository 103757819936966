import * as actions from 'store/deal/dealActions';
import { fetchByDealType, fetchByLocation } from 'lib/clients/fetchDeals';
import { createAsyncLogic } from 'lib/logic';
import { STORE_STATUS } from 'lib/enums/store';
import { getAccessToken, getFlightBookerToken, getQhUserId } from 'store/user/userSelectors';
import { getSearchQuery } from 'store/search/searchSelectors';
import { getDealType, getRegion } from '../dealSelectors';
import { getTreatments } from 'store/split/splitSelectors';

export const fetchDealsLogic = createAsyncLogic({
  type: actions.fetchDeals,
  latest: true,
  async process({ getState, action }, dispatch) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(actions.setStatus(STORE_STATUS.PENDING));

    const limit = action?.payload?.limit ?? 4;
    const state = getState();
    const accessToken = getAccessToken(state);
    const flightBookerToken = getFlightBookerToken(state);
    const qhUserId = getQhUserId(state);
    const { payWith, ...query } = getSearchQuery(state);
    const region = getRegion(state);
    const dealType = getDealType(state);
    const code = dealType.code === 'all_deals' ? undefined : dealType.code;

    const treatments = getTreatments(state);
    const shouldUseRedemptionsAB = treatments?.['redemptions-ab-1']?.treatment === 'new';

    const handler = !dealType || dealType?.code === 'all_deals' ? fetchByLocation : fetchByDealType;

    const handlerQuery = {
      ...query,
      adults: query.adults,
      dealType: code,
      location: region?.fullName,
      limit,
      accessToken,
      flightBookerToken,
      qhUserId,
      payWith: shouldUseRedemptionsAB ? 'points' : payWith,
    };

    const { results, meta } = await handler(handlerQuery);

    dispatch(actions.setDeals({ results, meta }));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(actions.setStatus(STORE_STATUS.RESOLVED));

    const fetchByLocationHandler = await fetchByLocation(handlerQuery);

    const fetchByLocationCall = (locationHandler) => {
      const locationResults = locationHandler.results;
      const filters = locationResults.map((result) => result.dealType);
      return { filters, locationResults };
    };
    const { filters, locationResults } = fetchByLocationCall(fetchByLocationHandler);

    dispatch(actions.setFilters({ filters }));
    dispatch(actions.setLocationDeals({ locationResults }));
  },
  onError({ parsedError }, dispatch) {
    dispatch(actions.setError(parsedError));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(actions.setStatus(STORE_STATUS.FAILED));
  },
});
