import * as client from 'lib/clients/fetchSiteMessage';
import * as actions from './siteMessageActions';
import { createAsyncLogic } from 'lib/logic';
import { getPageName } from '../../store/router/routerSelectors';

const fetchSiteMessageLogic = createAsyncLogic({
  type: actions.fetchSiteMessage,
  latest: true,
  async process({ getState }, dispatch) {
    const state = getState();

    const page = getPageName(state);
    const { message } = await client.fetchSiteMessage({ pageName: page });

    dispatch(
      actions.setSiteMessage({
        message,
      }),
    );
  },
  onError(_, dispatch) {
    dispatch(actions.clearSiteMessage());
  },
});

export const logic = [fetchSiteMessageLogic];
