import React, { FC } from 'react';
import { Global } from '@emotion/core';
import printStyles from 'components/BookingConfirmationPage/printStyles';
import Disclaimer from './Disclaimer';
import { Footer as GlobalFooter } from '@qga/components';
import {
  ABN,
  FOOTER_LOGO,
  FOOTER_LOGO_LABEL,
  FOOTER_NAVIGATION_LINKS,
  FOOTER_SUPPORT_LINKS,
  QTA_FOOTER_NAVIGATION_LINKS,
  QTA_FOOTER_SUPPORT_LINKS,
  HOTELS_URL,
} from 'config';
import { getFooterNavigationLinksGclid } from 'config/navigationMenuGclid.qantas';
import { getIsMobileApp } from 'store/ui/uiSelectors';
import { getPageName } from 'store/router/routerSelectors';
import { useSelector } from 'react-redux';
import { useDataLayer } from 'hooks/useDataLayer';
import { useQueryString } from 'hooks/useQueryString';
import { getGclid } from 'components/HomePage/sessionStorage';
import useMenuClickEvent from 'hooks/useMenuClickEvent';

const Footer: FC = () => {
  const isMobileApp = useSelector(getIsMobileApp);
  const pageName = useSelector(getPageName);
  const isHomepage = ['home-page', 'airbnb'].includes(pageName ?? '');
  const { emitInteractionEvent } = useDataLayer();
  const { menuClickEvent } = useMenuClickEvent();

  const queryString = useQueryString();
  const gclid = getGclid();
  const addGclidParam = gclid && isHomepage;

  const footerNavigationLinks = addGclidParam ? getFooterNavigationLinksGclid(gclid) : FOOTER_NAVIGATION_LINKS();
  const footerLinks = isMobileApp ? QTA_FOOTER_NAVIGATION_LINKS : footerNavigationLinks;
  const supportLinks = isMobileApp ? QTA_FOOTER_SUPPORT_LINKS : FOOTER_SUPPORT_LINKS;

  if (isMobileApp && isHomepage) {
    return null;
  }

  return (
    <>
      <Global styles={printStyles} />
      <Disclaimer />
      <GlobalFooter
        landingPageUrl={HOTELS_URL}
        links={footerLinks}
        disclaimerLinks={supportLinks}
        abn={ABN}
        footerLogoSrc={FOOTER_LOGO}
        footerLogoLabel={FOOTER_LOGO_LABEL}
        onInteraction={emitInteractionEvent}
        onMenuClick={menuClickEvent}
        queryString={queryString}
      />
    </>
  );
};

export default Footer;
