import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTreatments } from 'store/split/splitSelectors';
import { menuClick } from 'store/ui/uiActions';
import { startsWith } from 'lodash';
import { HOTELS_URL } from 'config';

const useMenuClickEvent = () => {
  const dispatch = useDispatch();
  const treatments = useSelector(getTreatments);
  const isGA4 = treatments?.ga4_schema?.treatment === 'on';

  const menuClickEvent = useCallback(
    ({ itemText, url }) => {
      const parsedUrl = startsWith(url, 'http') ? url : HOTELS_URL.concat(url);
      dispatch(
        menuClick({
          itemText: itemText,
          url: parsedUrl,
          ga4: isGA4,
        }),
      );
    },
    [dispatch, isGA4],
  );

  return { menuClickEvent };
};

export default useMenuClickEvent;
