import React, { FC } from 'react';
import { qantasHotels } from '@qga/roo-ui/logos';
import { rem } from 'polished';
import styled from '@emotion/styled';
import { Container, Flex, Link } from '@qga/roo-ui/components';
import { PhoneMenu } from './PhoneMenu';
import { QffMenu } from './QffMenu';
import { themeGet } from 'styled-system';
import { HeaderProps } from '../types';
import { LoginTooltip } from '../LoginTooltip';
import { useGlobalHeaderContext } from '../GlobalHeaderContext';
import { isArray, head } from 'lodash';
import omit from 'lodash/omit';
import { getQueryParams } from 'store/router/routerSelectors';
import { useSelector } from 'react-redux';
import stringifyQueryValues from 'lib/search/stringifyQueryValues';
import { getProperty } from 'store/property/propertySelectors';
import { getTreatments } from 'store/split/splitSelectors';
import { HOTELS_URL } from 'config/env';
import useMenuClickEvent from 'hooks/useMenuClickEvent';

const qantasLogoClip = '71px';
const Logo = styled.img`
  display: inline-flex;
  clip-path: inset(0 0 -1px ${qantasLogoClip});
  margin-left: -${qantasLogoClip};
  ${themeGet('mediaQueries.1')} {
    width: ${rem('186px')};
  }
`;

const HeaderInner = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  padding-left: ${themeGet('space.4')};
  border-bottom: 1px solid ${themeGet('colors.greys.porcelain')};
`;

export const HeaderPhone: FC<HeaderProps> = ({
  universalNavEnabled = true,
  qffLoginEnabled = true,
  homepageLogoSrc = qantasHotels,
  homepageLogoLabel = 'Qantas',
  LogoItemRenderer,
  navEnabled = true,
  arrowDirection = 'up',
}: HeaderProps) => {
  const { onInteraction } = useGlobalHeaderContext();
  const query = useSelector(getQueryParams);
  const property = useSelector(getProperty);
  const splitTreatments = useSelector(getTreatments);
  const homepageSave = splitTreatments?.homepage_save?.treatment === 'on';
  if (!query?.location && property) query.location = property.regionFullName;
  const omitParams = ['searchType', 'sortBy', 'offerId', 'roomTypeId', 'excludeParams'];
  const queryString = stringifyQueryValues(omit(query, omitParams));
  const homepageQueryString = homepageSave ? `/hotels?${queryString}` : '/hotels';
  const homepageUrl = homepageSave ? `${HOTELS_URL}?${queryString}` : HOTELS_URL;
  const { menuClickEvent } = useMenuClickEvent();

  const tooltipArrowDirection = isArray(arrowDirection) ? head(arrowDirection) : arrowDirection;

  const trackInteraction = () => {
    onInteraction({ type: 'Phone Header Logo', value: 'Selected' });
    menuClickEvent({ itemText: 'Phone Header Logo', url: homepageUrl });
  };

  return (
    <Container p={0}>
      {navEnabled && (
        <HeaderInner>
          <Flex alignItems="center">
            <Link href={homepageQueryString} mr={4} p={1} data-testid="header-logo-link" onClick={trackInteraction}>
              {LogoItemRenderer ? (
                <LogoItemRenderer src={homepageLogoSrc} alt={homepageLogoLabel} role="img" aria-label={`${homepageLogoLabel} Logo`} />
              ) : (
                <Logo src={homepageLogoSrc} alt={homepageLogoLabel} role="img" aria-label={`${homepageLogoLabel} Logo`} />
              )}
            </Link>
          </Flex>
          <Flex data-print-style="hidden" data-testid="phone-menus">
            <PhoneMenu qffLoginEnabled={qffLoginEnabled} universalNavEnabled={universalNavEnabled} />
            {qffLoginEnabled && <QffMenu />}
          </Flex>
        </HeaderInner>
      )}

      {qffLoginEnabled && <LoginTooltip arrowDirection={tooltipArrowDirection} />}
    </Container>
  );
};
