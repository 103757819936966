import { captureErrorInSentry, isNonHttpError, parseError } from 'lib/errors';
import noop from 'lodash/noop';
import { createLogic } from 'redux-logic';

import type { CreateLogic } from 'redux-logic/definitions/logic';
import type { ArgumentAction, StandardAction } from 'redux-logic/definitions/action';
import type { ParsedHttpError } from 'types/error';
import { AxiosError } from 'axios';
import { ZodError } from 'zod';

type createAsyncLogicProps<
  State extends object,
  Payload extends Record<string, unknown> | undefined = undefined,
  Meta extends Record<string, unknown> | undefined = undefined,
  Dependency extends object = Record<string, never>,
  Context extends Record<string, unknown> | undefined = undefined,
  Type extends string = string,
  Action extends StandardAction<Type, Payload, Meta> = StandardAction<Type, Payload, Meta>,
> = CreateLogic.Config<State, Action, Dependency, Context, Type> & {
  onError?: (
    ctx: CreateLogic.Config.Process.DepObj<State, Action, Dependency, Context> & {
      error: AxiosError | ZodError;
      parsedError: ParsedHttpError;
    },
    dispatch: <T extends ArgumentAction<string, undefined, undefined>>(action: T) => T,
    done: () => void,
  ) => unknown | void;
  onFinally?: (
    ctx: CreateLogic.Config.Process.DepObj<State, Action, Dependency, Context>,
    dispatch: <T extends ArgumentAction<string, undefined, undefined>>(action: T) => T,
    done: () => void,
  ) => void;
};

export const createAsyncLogic = <
  TState extends object,
  TPayload extends Record<string, unknown> | undefined = undefined,
  TMeta extends Record<string, unknown> | undefined = undefined,
  TDependency extends object = Record<string, never>,
  TContext extends Record<string, unknown> | undefined = undefined,
  TType extends string = string,
  TAction extends StandardAction<TType, TPayload, TMeta> = StandardAction<TType, TPayload, TMeta>,
>({
  process,
  onError = noop,
  onFinally = noop,
  ...logicOptions
}: createAsyncLogicProps<TState, TPayload, TMeta, TDependency, TContext, TType, TAction>) => {
  return createLogic({
    ...logicOptions,
    async process(ctx, dispatch, done) {
      if (typeof process !== 'function') return;
      try {
        if (typeof process === 'function') await process(ctx, dispatch, done);
      } catch (error) {
        const parsedError = parseError(error);
        const onErrorReslt = await onError({ ...ctx, error, parsedError }, dispatch, done);
        if (onErrorReslt !== false) {
          if (isNonHttpError(error)) {
            captureErrorInSentry(error);
          }
        }
      } finally {
        await onFinally(ctx, dispatch, done);
        done();
      }
    },
  });
};
