import { createReducer } from '@reduxjs/toolkit';
import {
  fetchSearchResultsSuccess,
  fetchSearchResultsFailure,
  setLoading,
  fetchSearchResults,
  clearResults,
  setSearchMeta,
} from './searchActions';

const initialState = {
  results: [],
  meta: {},
  isLoading: false,
  error: null,
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(fetchSearchResults, (state) => {
      state.error = null;
    })

    .addCase(fetchSearchResultsSuccess, (state, { payload }) => {
      state.meta = payload.meta;
      state.results = payload.results;
    })

    .addCase(fetchSearchResultsFailure, (state, { payload }) => {
      state.error = payload;
      state.meta = {};
      state.results = [];
    })

    .addCase(clearResults, (state) => {
      state.error = null;
      state.meta = {};
      state.results = [];
    })

    .addCase(setLoading, (state, { payload }) => {
      state.isLoading = payload;
    })

    .addCase(setSearchMeta, (state, { payload }) => {
      state.meta = payload.meta;
    }),
);
