/* eslint-disable react/display-name */
import React, { FC, Fragment, memo } from 'react';
import { Flex, Icon } from '@qga/roo-ui/components';
import { QFF_SIGNUP_URL } from 'config';
import { formatNumber, formatName } from '../../../helpers';
import { MembershipTier, StyledText, JoinNowText, JoinNowLink } from './primitives';
import { useGlobalHeaderContext } from '../../../GlobalHeaderContext';
import { QffAuthData } from '../../../types';

const QffAuthDetailsMemo = memo<QffAuthData>(
  ({ isAuthenticated, title, firstName, lastName, membershipTier, pointsBalance, memberId }: QffAuthData) => (
    <Fragment>
      {isAuthenticated && (
        <Flex flexDirection="column" color="white" justifyContent="flex-start">
          <StyledText data-hj-suppress data-testid="member-name-id">
            {formatName({
              title: title || '',
              firstName: firstName || '',
              lastName: lastName || '',
            })}{' '}
            ({memberId})
          </StyledText>
          <Flex flexWrap="wrap">
            <MembershipTier data-testid="member-tier">{membershipTier} Frequent Flyer</MembershipTier>
            <StyledText mx={1}>-</StyledText>
            <StyledText data-testid="member-points-balance">{formatNumber(pointsBalance || 0)} points</StyledText>
          </Flex>
        </Flex>
      )}
      {!isAuthenticated && (
        <JoinNowLink data-testid="sign-up-link" href={`${QFF_SIGNUP_URL}?thirdParty=hooroos`}>
          <JoinNowText mr={1}>Not a member? </JoinNowText>
          <JoinNowText fontWeight={'bold'}>Join now</JoinNowText> <Icon name="arrowForward" size={16} color="white" />
        </JoinNowLink>
      )}
    </Fragment>
  ),
);

export const QffAuthDetails: FC = () => {
  const { qffAuthData } = useGlobalHeaderContext();

  return <QffAuthDetailsMemo {...qffAuthData} />;
};
