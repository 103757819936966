/* eslint-disable react/display-name */
import React, { FC } from 'react';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { CaretDownIcon } from '@radix-ui/react-icons';
import isEmpty from 'lodash/isEmpty';
import { Flex, Icon, Container } from '@qga/roo-ui/components';
import { GlobalHeaderContextType, useGlobalHeaderContext } from '../../GlobalHeaderContext';
import {
  NavigationMenuRoot,
  NavigationMenuList,
  NavigationMenuTrigger,
  NavigationMenuLink,
  NavigationMenuLinkSubmenu,
  NavigationMenuLinkSubmenuTitle,
  NavigationMenuContent,
  SubMenuTitle,
  NavigationMenuItemBtn,
} from './NavigationLinks.style';
import { LinkOptions } from '../../types';
import useMenuClickEvent from 'hooks/useMenuClickEvent';

type MemoProps = Pick<GlobalHeaderContextType, 'desktopLinks' | 'onInteraction' | 'queryString'>;

const NavigationLinksMemo = React.memo(({ desktopLinks, onInteraction, queryString }: MemoProps) => {
  const { menuClickEvent } = useMenuClickEvent();
  const onClickHandler = (trackingName: string, href: string | undefined) => {
    onInteraction({
      type: 'Site Navigation',
      value: `${trackingName} Selected`,
    });
    menuClickEvent({ itemText: `${trackingName} Navigation Selected`, url: href ? href : '' });
  };

  const getHref = (link: LinkOptions) => {
    const { href, usesQueryParams } = link;
    const withQueryParams = usesQueryParams && queryString;
    return `${href}${withQueryParams ? `?${queryString}` : ''}`;
  };

  return (
    <NavigationMenuRoot>
      <Container>
        <NavigationMenuList>
          {desktopLinks.map((link) => {
            const { trackingName, dropdown, rel, target, href } = link;

            if (dropdown) {
              return (
                <NavigationMenuItemBtn key={trackingName}>
                  <NavigationMenuTrigger onClick={() => onClickHandler(trackingName, href)}>
                    {trackingName}
                    <CaretDownIcon className="CaretDown" aria-hidden />
                  </NavigationMenuTrigger>
                  <NavigationMenuContent>
                    {dropdown.map(({ children, submenu = [], href }, index) => (
                      <Flex flexDirection="column" width="100%" key={index}>
                        <SubMenuTitle>
                          <NavigationMenuLinkSubmenuTitle href={href} onClick={() => onClickHandler(trackingName, href)}>
                            {children}
                            <Icon name="chevronRight" size={16} />
                          </NavigationMenuLinkSubmenuTitle>
                        </SubMenuTitle>

                        {!isEmpty(submenu) && (
                          <>
                            {submenu.map((subMenuItem, submenuIndex) => {
                              const { trackingName, href, children } = subMenuItem;
                              return (
                                <NavigationMenuLinkSubmenu
                                  key={submenuIndex}
                                  href={href}
                                  target=""
                                  onClick={() => onClickHandler(trackingName || '', href)}
                                >
                                  {children}
                                </NavigationMenuLinkSubmenu>
                              );
                            })}
                          </>
                        )}
                      </Flex>
                    ))}
                  </NavigationMenuContent>
                </NavigationMenuItemBtn>
              );
            } else {
              return (
                <NavigationMenu.Item key={trackingName}>
                  <NavigationMenuLink
                    to={getHref(link)}
                    onClick={() => onClickHandler(trackingName, href)}
                    rel={rel}
                    target={target}
                    href=""
                  >
                    {trackingName}
                  </NavigationMenuLink>
                </NavigationMenu.Item>
              );
            }
          })}
        </NavigationMenuList>
      </Container>
    </NavigationMenuRoot>
  );
});

export const NavigationLinks: FC = () => {
  const { desktopLinks, onInteraction, queryString } = useGlobalHeaderContext();

  return <NavigationLinksMemo desktopLinks={desktopLinks} onInteraction={onInteraction} queryString={queryString} />;
};
