import { createAction } from '@reduxjs/toolkit';
import { ExclusiveOffer } from 'types/content';
import { FetchExclusiveOfferArg } from 'lib/clients/fetchExclusiveOffer';

export const setLoading = createAction('exclusiveOffer/SET_LOADING', (loading) => ({ payload: loading }));
export const clearExclusiveOffer = createAction('exclusiveOffer/CLEAR_EXCLUSIVE_OFFER');
export const fetchExclusiveOffer = createAction<FetchExclusiveOfferArg>('exclusiveOffer/FETCH_EXCLUSIVE_OFFER');
export const setExclusiveOffer = createAction<ExclusiveOffer>('exclusiveOffer/SET_EXCLUSIVE_OFFER');
export const fetchExclusiveOfferList = createAction('exclusiveOffer/FETCH_EXCLUSIVE_OFFER_LIST');
export const setExclusiveOfferList = createAction<unknown[]>('exclusiveOffer/SET_EXCLUSIVE_OFFER_LIST');
export const emitPropertyPageExclusiveGa4Event = createAction('exclusiveOffer/EMIT_PROPERTY_PAGE_EXCLUSIVE_GA4_EVENT');
