import flatMap from 'lodash/flatMap';
import reduce from 'lodash/reduce';

const tierInstance = (pointsTierInstances, instanceFamilyId) => {
  const tierInstances = flatMap(pointsTierInstances);

  const tier = reduce(tierInstances, (accum, tierInstance) => {
    if (tierInstance.family === instanceFamilyId) {
      accum = tierInstance;
    }
    return accum;
  });

  return tier;
};

export default tierInstance;
