import { theme as baseTheme } from '@qga/roo-ui';
import { fontSizes } from './fontSizes';
import { colors } from './colors';

export const buttons = {
  inverted: {
    color: colors.brand.primary,
    backgroundColor: colors.white,
  },
  menuButton: {
    color: colors.greys.charcoal,
    backgroundColor: colors.white,
    border: baseTheme.borders[1],
    borderColor: colors.greys.alto,
    fontSize: fontSizes.base,
    fontWeight: baseTheme.fontWeights.normal,
    lineHeight: baseTheme.lineHeights.tight,
    letterSpacing: 'normal',
    textTransform: 'none',
    '&&:hover:not(:disabled)': {
      borderColor: colors.greys.alto,
      backgroundColor: colors.white,
    },
    '&:active': {
      borderColor: colors.brand.secondary,
      backgroundColor: colors.ui.infoBackground,
    },
    '&:focus': {
      borderColor: colors.brand.secondary,
      backgroundColor: colors.ui.infoBackground,
    },
  },
  mobilePillButton: {
    border: 0,
    padding: 0,
    backgroundColor: 'transparent',
    textTransform: 'none',
    letterSpacing: 'normal',
    color: colors.white,
    minWidth: '126px',
    height: '48px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  dealsFilterButton: {
    border: baseTheme.borders[1],
    borderColor: colors.greys.alto,
    borderRadius: baseTheme.radii.rounded,
    padding: '0.4rem 0.45rem',
  },
  dealsOptionButton: {
    borderColor: colors.greys.alto,
  },
  dealsOptionButtonActive: {
    color: colors.brand.primary,
    borderColor: colors.brand.primary,
  },
  searchBannerCta: {
    color: colors.ui.link,
    '&:hover': {
      color: colors.ui.linkHover,
    },
  },
};
