import React, { FC } from 'react';
import { rem } from 'polished';
import { Box, Flex, Link, Image, Text, Icon } from '@qga/roo-ui/components';
import { baggage } from '@qga/roo-ui/assets';
import { useGlobalHeaderContext } from '../GlobalHeaderContext';
import { TooltipWrapper, ArrowUp, Card, ArrowRight } from './LoginTooltip.style';

export interface TooltipProps {
  arrowDirection?: string;
}

export const LoginTooltip: FC<TooltipProps> = ({ arrowDirection }: TooltipProps) => {
  const { onDismissLoginTooltip, onInteraction, qffAuthData, onClickLogin, displayLoginTooltip } = useGlobalHeaderContext();

  const handleDismiss = () => {
    if (typeof onDismissLoginTooltip === 'function') onDismissLoginTooltip();
    onInteraction({ type: 'Login tooltip', value: 'LoginTooltip Dismissed' });
  };
  if (!displayLoginTooltip || qffAuthData.isAuthenticated) return null;

  return (
    <TooltipWrapper
      width={['100%', '100%', '350px']}
      mt={[-3, -3, 0]}
      mr={[0, 0, -3]}
      data-testid="login-tooltip"
      flexDirection={['column', 'column', 'row']}
    >
      {arrowDirection === 'up' && <ArrowUp />}
      <Card justifyContent={['space-between', 'space-between', 'center']} alignItems="center">
        <Flex justifyContent="center" alignItems="center">
          <Box display="block" width={rem('86px')}>
            <Image alt={baggage} src={baggage} height={rem('46px')} width="100%" pr={4} />
          </Box>
          <Text display="block" fontSize="base">
            <Link mr={1} underline color="inherit" role="button" onClick={onClickLogin} data-testid="login-link">
              Login{' '}
            </Link>
            to access incredible deals for members!
          </Text>
        </Flex>

        <Box>
          <Icon name="close" ml={8} mr={0} size={18} color="greys.dusty" onClick={handleDismiss} data-testid="close-icon" />
        </Box>
      </Card>
      {arrowDirection === 'right' && <ArrowRight />}
    </TooltipWrapper>
  );
};
