import { useState, useCallback } from 'react';
import { useEvent, useMount } from 'react-use';
import throttle from 'lodash/throttle';
import { BREAK_POINTS_PX, TRUNCATE_BREAK_POINTS_PX } from 'lib/theme';

export const useBreakpoints = () => {
  const [matchingBreakpoints, setMatchingBreakpoints] = useState([]);

  const calculateBreakPoints = () => {
    const currentWidth = window.innerWidth;
    const matchingBreakpoints = BREAK_POINTS_PX.filter((minWidth) => currentWidth >= minWidth);
    setMatchingBreakpoints(matchingBreakpoints);
  };

  const onResize = throttle(calculateBreakPoints, 200);

  useEvent('resize', onResize);
  useMount(calculateBreakPoints);

  const isLessThanBreakpoint = useCallback(
    (breakpoint) => !matchingBreakpoints.includes(BREAK_POINTS_PX[breakpoint]),
    [matchingBreakpoints],
  );
  const isGreaterThanOrEqualToBreakpoint = useCallback(
    (breakpoint) => matchingBreakpoints.includes(BREAK_POINTS_PX[breakpoint]),
    [matchingBreakpoints],
  );

  return { isLessThanBreakpoint, isGreaterThanOrEqualToBreakpoint };
};

export const useTruncateBreakpoints = () => {
  const [matchingBreakpoints, setMatchingBreakpoints] = useState([]);

  const calculateBreakPoints = () => {
    const currentWidth = window.innerWidth;
    const matchingBreakpoints = TRUNCATE_BREAK_POINTS_PX.filter((minWidth) => currentWidth >= minWidth);
    setMatchingBreakpoints(matchingBreakpoints);
  };

  const onResize = throttle(calculateBreakPoints, 200);

  useEvent('resize', onResize);
  useMount(calculateBreakPoints);

  const isLessThanBreakpoint = useCallback(
    (breakpoint) => !matchingBreakpoints.includes(TRUNCATE_BREAK_POINTS_PX[breakpoint]),
    [matchingBreakpoints],
  );
  const isGreaterThanOrEqualToBreakpoint = useCallback(
    (breakpoint) => matchingBreakpoints.includes(TRUNCATE_BREAK_POINTS_PX[breakpoint]),
    [matchingBreakpoints],
  );

  return { isLessThanBreakpoint, isGreaterThanOrEqualToBreakpoint };
};
