import { createSelector } from '@reduxjs/toolkit';
import filter from 'lodash/filter';
import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import map from 'lodash/map';
import take from 'lodash/take';
import reduce from 'lodash/reduce';
import { getQueryPage, getQueryPayWith } from 'store/router/routerSelectors';
import { getTreatments } from 'store/split/splitSelectors';
import { ALLOWED_DEAL_TYPES, ALL_DEALS_RESULTS_LIMIT, DEAL_TYPE_RESULTS_LIMIT, DEFAULT_DEAL_TYPE, DEFAULT_DEAL_TYPE_ORDER } from 'config';
import { Deal, DealType } from 'types/deals';
import { STORE_STATUS } from 'lib/enums/store';
import sortBy from 'lodash/sortBy';

export const getDeals = (state) => state.deals;
export const getDealType = (state) => state.deals.dealType || DEFAULT_DEAL_TYPE;
export const getFilters = (state) => state.deals.filters;
export const getLocationDeals = (state) => state.deals.locationDeals;

export const getCampaignBanner = (state) => state.deals.pageContent?.campaignBanner;
export const getFooterLinks = (state) => state.deals.pageContent?.footerLinks || [];
export const getHeroImage = (state) => state.deals.pageContent?.defaultBanner;
export const getNavigationItems = (state) => state.deals.pageContent?.navigation || [];
export const getPageContent = (state) => state.deals.pageContent;
export const getRegion = (state) => state.deals.pageContent?.region;
export const getRegionName = (state) => state.deals.pageContent?.region?.name;
export const getStatus = (state) => state.deals.status;

export const getAvailableDealTypes = createSelector(getFilters, ({ filters }) => {
  const availableDealCodes = map(filters, 'code');
  const availableDealTypes = filter(ALLOWED_DEAL_TYPES, ({ code }: DealType) => availableDealCodes.includes(code));

  return [DEFAULT_DEAL_TYPE, ...availableDealTypes] as DealType[];
});

export const getHasValidQuery = createSelector(getQueryPage, getQueryPayWith, (page, payWith) => !!page && !!payWith);
export const getResults = createSelector(getDeals, ({ deals }) => deals?.results || []);
export const getMeta = createSelector(getDeals, ({ deals }) => deals?.meta || []);

export const getDealsTypeOrder = createSelector(getTreatments, (treatment) => {
  const dealsTypeOrder = treatment['deals_hub_type_order'];
  const treatmentConfig = dealsTypeOrder?.config;

  return treatmentConfig || DEFAULT_DEAL_TYPE_ORDER;
});

export const getSortedAvailableDealTypes = createSelector(
  getAvailableDealTypes,
  getDealsTypeOrder,
  (availableDealTypes, dealsTypeOrder) => {
    return sortBy(availableDealTypes, (dealType) => {
      return dealsTypeOrder[dealType.code];
    });
  },
);

export const getDealsByType = createSelector(
  getResults,
  getDealType,
  getQueryPayWith,
  getStatus,
  getTreatments,
  (results, dealType, payWith, status, treatments) => {
    if (status !== STORE_STATUS.RESOLVED) return [];
    const isAllDeals = dealType.code === DEFAULT_DEAL_TYPE.code;
    const limit = isAllDeals ? ALL_DEALS_RESULTS_LIMIT : DEAL_TYPE_RESULTS_LIMIT;
    const dealsByType = flatMap(results, ({ dealType, properties }) => {
      if (typeof dealType === 'undefined') return [];
      const matchedDealType = find(ALLOWED_DEAL_TYPES, ['code', dealType.code]);
      if (typeof matchedDealType === 'undefined') return [];
      const isClassic = matchedDealType.code === 'classic_rewards';

      const withLimit = take(properties, limit);
      const cardsData = withLimit.map((deal: Deal) => {
        const updatedPayWith = treatments?.['redemptions-ab-1']?.treatment === 'new' ? 'points' : payWith;
        const pricing = isClassic ? deal.pricing?.[0] : find(deal.pricing, ['payWith', updatedPayWith]);
        const result = {
          id: deal.propertyId,
          propertyName: deal.propertyName,
          imageSrc: deal.mainImage.urlMedium,
          imageAltTag: deal.mainImage.caption,
          isNonrefundable: deal.cancellationPolicy.isNonrefundable,
          total: pricing?.charges.total,
          totalCash: pricing?.charges.totalCash,
          hasDiscount:
            !!Number(pricing?.charges.totalBeforeDiscount.amount) &&
            pricing?.charges.totalBeforeDiscount.amount !== pricing?.charges.total.amount,
          totalBeforeDiscount: pricing?.charges.totalBeforeDiscount,
          promotionName: deal?.promotion?.name,
          customerRating: deal.customerRatings[0],
          rating: deal.rating,
          ratingType: deal.ratingType,
          pointsEarned: pricing?.pointsEarned,
          offerId: deal.offerId,
          offerType: deal.offerType,
          offerName: deal.offerName,
          country: deal?.address?.country,
          checkIn: deal.checkIn,
          checkOut: deal.checkOut,
          propertyUrl: `/properties/${deal.propertyId}?checkIn=${deal.checkIn}&checkOut=${deal.checkOut}&adults=2&featuredOfferId=${deal.offerId}`,
        };

        if (typeof result.totalCash === 'undefined') delete result.totalCash;

        return result;
      });

      return {
        dealType: matchedDealType,
        results: cardsData,
        hasMore: properties.length > withLimit.length,
        totalCount: properties.length,
        isAllDeals: isAllDeals,
      };
    });

    return dealsByType;
  },
);

export const getDealsGa4Properties = createSelector(getDealsByType, (dealsByType) => {
  const dealsProperties = reduce(
    dealsByType,
    (accum, item) => {
      const mappedProperties = () => {
        const properties = reduce(
          item.results,
          (accum, result) => {
            const mappedProperty = {
              property: { id: result?.id, name: result?.propertyName, hasOffer: result?.promotionName ? true : false },
              roomType: { name: '' },
              offer: {
                charges: { total: result?.total, totalCash: result?.totalCash ? result?.totalCash : { amount: '0', currency: 'AUD' } },
              },
            };
            return [...accum, mappedProperty];
          },
          [],
        );
        return properties;
      };
      return [...accum, ...mappedProperties()];
    },
    [],
  );
  return dealsProperties;
});

export const getFooterLinksByCategory = createSelector(getFooterLinks, (footerLinks) => {
  return footerLinks.map(({ title, links }) => ({
    title: title,
    linkList: !links
      ? []
      : links.map((link) => ({
          testId: link.slug,
          trackingName: link.fullName,
          children: link.name,
          to: `/search/list?location=${link.fullName}`,
        })),
  }));
});
