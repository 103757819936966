import { emitSearchResultsEvent } from 'store/search/searchActions';
import { EventType, createEvent } from '@qantasexperiences/analytics';
import { emitDealsPageResults } from 'store/deal/dealActions';
import { emitRecommendedPropertiesResult } from 'store/recommendedProperty/recommendedPropertyActions';
import { emitPromoAreaGa4Results } from 'store/promoArea/promoAreaActions';

const emitViewItemListEvent = ({ payload }) => {
  if (payload.ga4) {
    return createEvent({
      type: EventType.VIEW_ITEM_LIST,
      payload: {
        type: payload?.type,
        listName: payload?.listName,
        location: payload?.query?.location || payload?.query?.regionName,
        category: payload?.category,
        payWith: payload?.query?.payWith,
        availableProperties: payload?.results?.length,
        availableRooms: payload?.results?.length,
        checkIn: payload?.query?.checkIn,
        checkOut: payload?.query?.checkOut,
        adults: payload?.query?.adults,
        children: payload?.query?.children,
        infants: payload?.query?.infants,
        currency: payload?.currency,
        hasOffer: payload?.hasOffer,
        results: payload?.results,
        quantity: payload?.quantity,
        pointsConversion: payload.pointsConversion,
      },
    });
  }

  return {
    event: `${payload?.listName} Results Ready`,
    location: payload?.query?.location,
  };
};

export default {
  [emitDealsPageResults]: emitViewItemListEvent,
  [emitSearchResultsEvent]: emitViewItemListEvent,
  [emitRecommendedPropertiesResult]: emitViewItemListEvent,
  [emitPromoAreaGa4Results]: emitViewItemListEvent,
};
