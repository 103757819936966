import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { rem } from 'polished';
import { Container, Box as BaseBox, BoxProps } from '@qga/roo-ui/components';
import { mediaQuery } from 'lib/styledSystem';

const printable = (props) => props.printable === false && { '@media print': { display: 'none' } };
const Box = styled(BaseBox)(printable);

const NarrowContainer = styled(Container)`
  max-width: 100%;
  ${mediaQuery.minWidth.md} {
    max-width: ${rem('812px')};
  }
`;

interface Props extends BoxProps {
  children: ReactNode;
  gutter?: string | number | string[] | number[];
  narrow?: boolean;
  printable?: boolean;
  paddingLeft?: number;
}

const PageBlock = React.forwardRef<ReactNode, Props>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ children, narrow, gutter, printable = true, paddingLeft, paddingRight, ...rest }: Props, ref) => {
    const Wrapper = narrow ? NarrowContainer : Container;

    return (
      <Box ref={ref} {...rest}>
        <Wrapper gutter={gutter} pl={paddingLeft} pr={paddingRight}>
          {children}
        </Wrapper>
      </Box>
    );
  },
);

PageBlock.displayName = 'PageBlock';

export default PageBlock;
