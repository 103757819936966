import React, { Component, PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import PageError from 'components/errors/PageError';
import { captureErrorInSentry } from 'lib/errors';
import { getApiError } from 'store/error/errorSelectors';

type State = { error: Record<string, unknown> | null };
type Props = PropsWithChildren<State>;

class ErrorBoundary extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { error: this.props.error ?? null };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error) {
      this.setState({ error: this.props.error });
    }
  }

  componentDidCatch(error, errorInfo) {
    captureErrorInSentry(error, errorInfo);
  }

  render() {
    return this.state.error ? <PageError {...this.state.error} /> : this.props.children;
  }
}

const mapStateToProps = (state) => ({
  error: getApiError(state),
});

export default connect(mapStateToProps)(ErrorBoundary);
