export const radii = {
  default: '4px',
  exaggerated: '8px',
  defaultRoundRightOnly: '0 4px 4px 0',
  defaultRoundLeftOnly: '4px 0 0 4px',
  defaultRoundTopOnly: '4px 4px 0 0',
  defaultRoundBottomOnly: '0 0 4px 4px',
  exaggeratedRoundRightOnly: '0 8px 8px 0',
  exaggeratedRoundLeftOnly: '8px 0 0 8px',
  exaggeratedRoundTopOnly: '8px 8px 0 0',
  exaggeratedRoundBottomOnly: '0 0 8px 8px',
  newPromoSash: '4px 0 10px 0',
};
