import React, { Fragment, FC, useEffect, useRef, useState } from 'react';
import { Box, Icon } from '@qga/roo-ui/components';
import { useGlobalHeaderContext } from '../../GlobalHeaderContext';
import { QffMenuWrapper, RedBox, QffMenuButtonWrapper } from './primitives';
import { QffMenuContent } from '../../QffMenuContent';
import { fixBodyScroll, unfixBodyScroll } from 'lib/browser';

export const QffMenu: FC = () => {
  const { qffAuthData, isQffMenuOpen, toggleQffMenu, onClickLogin, onInteraction } = useGlobalHeaderContext();
  const { isAuthenticated } = qffAuthData;
  const containerRef = useRef<HTMLInputElement>();
  const [translateY, setTranslateY] = useState(0);

  useEffect(() => {
    return () => unfixBodyScroll();
  }, []);

  useEffect(() => {
    const { offsetTop = 0 } = containerRef?.current || {};
    setTranslateY(offsetTop);
  }, [containerRef, setTranslateY]);

  const handleToggle = () => {
    onInteraction({
      type: 'QFF Login Dropdown',
      value: isQffMenuOpen ? 'Mobile Dropdown Collapsed' : 'Mobile Dropdown Expanded',
    });

    if (isQffMenuOpen) {
      unfixBodyScroll();
    } else {
      fixBodyScroll();
    }

    toggleQffMenu();
  };

  const handleLoginButtonClick = () => {
    onInteraction({
      type: 'QFF Login Button',
      value: 'Mobile Log In Button Selected',
    });

    onClickLogin();
  };

  return (
    <Box data-testid="qff-menu" ref={containerRef}>
      <QffMenuButtonWrapper>
        {isAuthenticated && !isQffMenuOpen && (
          <RedBox role="button" onClick={handleToggle} data-testid="show-details-button">
            <Icon name="person" />
          </RedBox>
        )}
        {isAuthenticated && isQffMenuOpen && (
          <Fragment>
            <RedBox role="button" onClick={handleToggle} data-testid="close-button">
              <Icon name="close" />
            </RedBox>
            <QffMenuWrapper data-testid="qff-menuwrapper" translateY={translateY}>
              <QffMenuContent />
            </QffMenuWrapper>
          </Fragment>
        )}

        {!isAuthenticated && (
          <RedBox role="button" onClick={handleLoginButtonClick} data-testid="login-button">
            Log in
          </RedBox>
        )}
      </QffMenuButtonWrapper>
    </Box>
  );
};
