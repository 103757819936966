import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { rem } from 'polished';
import { themeGet } from 'styled-system';
import { Flex } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import AppLink from 'components/AppLink';

export const NAV_HEIGHT = '64px';

export const NavigationMenuRoot = styled(NavigationMenu.Root)`
  background-color: ${themeGet('colors.greys.charcoal')};
  height: ${NAV_HEIGHT};
`;

export const NavigationMenuList = styled(NavigationMenu.List)`
  display: flex;
  list-style: none;
  margin: 0;
  padding-inline-start: 0;
`;

export const NavigationMenuTrigger = styled(NavigationMenu.Trigger)`
  background-color: transparent;
  color: inherit;
  border: none;
  font-size: ${themeGet('fontSizes.header.base')};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;

  &:focus {
    background-color: ${themeGet('colors.white')};
    color: ${themeGet('colors.brand.primary')};
  }

  &:hover {
    background-color: ${themeGet('colors.white')};
    color: ${themeGet('colors.brand.primary')};
  }
`;

export const NavigationMenuItemBtn = styled(NavigationMenu.Item)`
  height: ${NAV_HEIGHT};
  color: ${themeGet('colors.white')};
  padding: ${themeGet('space.2')};

  &:focus {
    background-color: ${themeGet('colors.white')};
    color: ${themeGet('colors.brand.primary')};
  }

  &:hover {
    background-color: ${themeGet('colors.white')};
    color: ${themeGet('colors.brand.primary')};
  }
`;

export const NavigationMenuItem = styled(NavigationMenu.Item)`
  font-size: ${themeGet('fontSizes.header.base')};
  padding: ${themeGet('space.2')};
  text-decoration: none;

  &:focus {
    background-color: ${themeGet('colors.white')};
  }

  &:hover {
    background-color: ${themeGet('colors.white')};
  }
`;

export const NavigationMenuLink = styled(AppLink)`
  text-decoration: none;
  padding: ${themeGet('space.5')};
  color: ${themeGet('colors.white')};
  font-size: ${themeGet('fontSizes.header.base')};
  height: ${NAV_HEIGHT};
  display: flex;

  &:focus {
    background-color: ${themeGet('colors.white')};
    color: ${themeGet('colors.brand.primary')};
  }

  &:hover {
    background-color: ${themeGet('colors.white')};
    color: ${themeGet('colors.brand.primary')};
  }
`;

const MenuLinkSubmenu = styled(NavigationMenu.Link)`
  text-decoration: none;
  padding: ${themeGet('space.2')};
  color: ${themeGet('colors.greys.steel')};

  &:focus {
    background-color: ${themeGet('colors.white')};
    color: ${themeGet('colors.brand.primary')};
  }

  &:hover {
    background-color: ${themeGet('colors.white')};
    color: ${themeGet('colors.brand.primary')};
  }
`;

export const NavigationMenuLinkSubmenu = styled(MenuLinkSubmenu)`
  font-size: ${themeGet('fontSizes.header.sm')};
`;

export const NavigationMenuLinkSubmenuTitle = styled(MenuLinkSubmenu)`
  font-size: ${themeGet('fontSizes.header.base')};
`;

export const NavigationMenuContent = styled(NavigationMenu.Content)`
  position: absolute;
  top: ${NAV_HEIGHT};
  display: flex;
  left: ${rem('85px')};
  width: ${rem('500px')};
  padding: ${themeGet('space.6')};
  color: ${themeGet('colors.greys.steel')};
  box-shadow: ${themeGet('shadows.modal')};
  background-color: ${themeGet('colors.white')};
  z-index: 500;
`;

export const SubMenuTitle = styled(Flex)`
  border-bottom: solid 1px ${themeGet('colors.greys.porcelain')};
`;
