import wrappedAxios from './wrappedAxios';
import { HOTELS_API_URL } from 'config';
import * as z from 'zod';
import { getAvaPropertyAvailabilityByCalendarParams, getAvaPropertyAvailabilityByCalendarResponse } from '../typechecking/ava';

export type FetchCalendarProps = z.infer<typeof getAvaPropertyAvailabilityByCalendarParams>;
export type Calendar = z.infer<typeof getAvaPropertyAvailabilityByCalendarResponse>;

export const fetchCalendar = async (props: FetchCalendarProps): Promise<Calendar> => {
  const { propertyId, ...params } = getAvaPropertyAvailabilityByCalendarParams.parse(props);
  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/exclusive-offers/${propertyId}/availability`,
    method: 'GET',
    params,
  });
  return getAvaPropertyAvailabilityByCalendarResponse.parse(response.data);
};

// UNUSED
export const fetchCalendarByOffer = async ({ propertyId, offerId, startDate, endDate, luxeOnly }) => {
  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/exclusive-offers/${propertyId}/availability/${offerId}`,
    method: 'GET',
    params: {
      startDate,
      endDate,
      luxeOnly,
    },
  });

  return response?.data;
};
