import wrappedAxios from './wrappedAxios';
import { HOTELS_API_URL } from 'config';
import { format as formatDate } from 'lib/date';

const dayOfYearFormat = 'yyyy-MM-dd';

export const createQuote = async ({ payload, accessToken, flightBookerToken, qhUserId, cookie, paidByDeposit }) => {
  const { propertyId, roomTypeId, offerId, checkIn, checkOut, adults, children, infants } = payload;

  const formattedCheckIn = checkIn ? formatDate(checkIn, dayOfYearFormat) : null;
  const formattedCheckOut = checkOut ? formatDate(checkOut, dayOfYearFormat) : null;

  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/quotes`,
    method: 'post',
    data: {
      propertyId,
      roomTypeId,
      offerId,
      checkIn: formattedCheckIn,
      checkOut: formattedCheckOut,
      adults: Number(adults) || 0,
      children: Number(children) || 0,
      infants: Number(infants) || 0,
      flightBookerToken,
      paidByDeposit: paidByDeposit,
    },
    accessToken,
    qhUserId,
    cookie,
  });

  return response.data;
};
