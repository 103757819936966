import { searchSuggestionDisplay } from 'store/homePage/homePageActions';
import { EventType, createEvent } from '@qantasexperiences/analytics';
import { getSearchLocalStorage } from '../../../components/HomePage/localStorage';

const getComponentVariant = (componentVariants, count) => {
  const propertySearches = componentVariants.filter(Boolean).length;
  const destinationSearches = count - propertySearches;
  if (destinationSearches > 0) {
    if (propertySearches > 0) {
      return 'destinations and properties';
    } else {
      return 'destinations';
    }
  } else {
    return 'properties';
  }
};

const emitSearchSuggestionDisplayEvent = () => {
  const recentSearches = getSearchLocalStorage();
  const count = recentSearches.length;
  const searchRecent = recentSearches.map((search) => search.location);
  const componentVariants = recentSearches.map((search) => search.isPropertySearch);
  const componentVariant = getComponentVariant(componentVariants, count);

  return createEvent({
    type: EventType.SEARCH_SUGGESTION_DISPLAY,
    payload: {
      componentVariant: componentVariant,
      count: count,
      searchRecent: searchRecent,
      viewMode: 'list',
    },
  });
};

// eslint-disable-next-line
// @ts-ignore
export default { [searchSuggestionDisplay]: emitSearchSuggestionDisplayEvent };
