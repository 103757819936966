import React, { FC, Fragment } from 'react';
import styled from '@emotion/styled';
import { rem } from 'polished';
import { qantasHotels } from '@qga/roo-ui/logos';
import { Container, Flex, Link } from '@qga/roo-ui/components';
import { QantasUniversalNav } from '../QantasUniversalNav';
import { HeaderProps } from '../types';
import { LoginTooltip } from '../LoginTooltip';
import { useGlobalHeaderContext } from '../GlobalHeaderContext';
import { NavigationLinks } from './NavigationLinks';
import { QffMenu } from './QffMenu';
import { isArray, last } from 'lodash';
import omit from 'lodash/omit';
import { getQueryParams } from 'store/router/routerSelectors';
import { useSelector } from 'react-redux';
import stringifyQueryValues from 'lib/search/stringifyQueryValues';
import { getProperty } from 'store/property/propertySelectors';
import { getTreatments } from 'store/split/splitSelectors';
import { HOTELS_URL } from 'config/env';
import useMenuClickEvent from 'hooks/useMenuClickEvent';

const Logo = styled.img`
  display: inline-flex;
  width: ${rem(186)};
`;

const HeaderInner = styled(Flex)`
  justify-content: space-between;
  align-items: flex-end;

  @media print {
    display: none;
  }
`;

export const HeaderDesktop: FC<HeaderProps> = ({
  universalNavEnabled = true,
  qffLoginEnabled = true,
  homepageLogoSrc = qantasHotels,
  LogoItemRenderer,
  navEnabled = true,
  arrowDirection = 'right',
}: HeaderProps) => {
  const { onInteraction } = useGlobalHeaderContext();
  const query = useSelector(getQueryParams);
  const property = useSelector(getProperty);
  const splitTreatments = useSelector(getTreatments);
  const homepageSave = splitTreatments?.homepage_save?.treatment === 'on';
  if (!query?.location && property) query.location = property.regionFullName;
  const omitParams = ['searchType', 'sortBy', 'offerId', 'roomTypeId', 'excludeParams', 'exclusiveOffer', 'propertyId'];
  const queryString = stringifyQueryValues(omit(query, omitParams));
  const homepageQueryString = homepageSave ? `/hotels?${queryString}` : '/hotels';
  const homepageUrl = homepageSave ? `${HOTELS_URL}?${queryString}` : HOTELS_URL;
  const tooltipArrowDirection = isArray(arrowDirection) ? last(arrowDirection) : arrowDirection;
  const { menuClickEvent } = useMenuClickEvent();

  const trackInteraction = () => {
    onInteraction({ type: 'Desktop Header Logo', value: 'Selected' });
    menuClickEvent({ itemText: 'Desktop Header Logo', url: homepageUrl });
  };

  return (
    <Fragment>
      {universalNavEnabled && <QantasUniversalNav />}
      {navEnabled && (
        <>
          <Container pr={4}>
            <HeaderInner>
              <Link href={homepageQueryString} mr={4} my={2} p={1} data-testid="header-logo-link" onClick={trackInteraction}>
                {LogoItemRenderer ? (
                  <LogoItemRenderer src={homepageLogoSrc} alt="Qantas" role="img" aria-label="Qantas Logo" />
                ) : (
                  <Logo src={homepageLogoSrc} alt="Qantas" role="img" aria-label="Qantas Logo" />
                )}
              </Link>
              {qffLoginEnabled && (
                <Flex data-print-style="hidden" mb={-2} data-testid="desktop-qff-menu" zIndex={1}>
                  <LoginTooltip arrowDirection={tooltipArrowDirection} />
                  <QffMenu />
                </Flex>
              )}
            </HeaderInner>
          </Container>
          <NavigationLinks />
        </>
      )}
    </Fragment>
  );
};
