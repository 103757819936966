import find from 'lodash/find';
import { createBooking as createBookingFromApi } from 'lib/clients/createBooking';
import { createBooking, setBooking, trackBooking } from 'store/booking/bookingActions';
import { push } from 'connected-next-router';
import { getFormData, getBookingClientRequestId, getBookingChannel } from 'store/checkout/checkoutSelectors';
import { getTreatments } from 'store/split/splitSelectors';
import { getQuoteReference, getQuote } from 'store/quote/quoteSelectors';
import { getQuoteDepositPay, getQuoteDepositPayReference } from 'store/quoteDepositPay/quoteDepositPaySelectors';
import { getAccessToken, getQhUserId } from 'store/user/userSelectors';
import { getDeviceFingerprint, getIpAddress, getDeviceFingerprintError } from 'store/userEnvironment/userEnvironmentSelectors';
import createBookingPayload from './createBookingPayload';
import { checkoutError, setBookingClientRequestId } from 'store/checkout/checkoutActions';
import createBookingClientRequestId from 'lib/checkout/createBookingClientRequestId';
import { BOOKING_STATES, BOOKING_ERROR_CODES } from 'lib/enums/booking';
import { getQffAuth } from 'lib/qffAuth';
import { createAsyncLogic } from 'lib/logic';

export const createBookingLogic = createAsyncLogic({
  type: createBooking,
  latest: true,
  async process({ getState }, dispatch) {
    dispatch(setBooking({ state: BOOKING_STATES.SUBMITTING }));
    const state = getState();
    const formData = getFormData(state);
    const deviceFingerprint = getDeviceFingerprint(state);
    const deviceFingerprintError = getDeviceFingerprintError(state);
    const ipAddress = getIpAddress(state);

    const isDepositPay = formData?.paymentMode === 'DEPOSIT';
    const originalQuote = getQuote(state);
    const originalQuoteReference = getQuoteReference(state);
    const depositPayQuote = getQuoteDepositPay(state);
    const depositPayQuoteReference = getQuoteDepositPayReference(state);

    const quote = isDepositPay ? depositPayQuote : originalQuote;
    const quoteReference = isDepositPay ? depositPayQuoteReference : originalQuoteReference;

    const clientRequestId = getBookingClientRequestId(state);
    const bookingChannel = getBookingChannel(state);
    const qhUserId = getQhUserId(state);
    const splits = getTreatments(state);
    const isGA4 = splits.ga4_schema?.treatment === 'on';
    const urlParams = new URLSearchParams(state.router.location.href);
    const ssAction = urlParams.get('ss_action');
    const isRebooked = ssAction === 'rebook';
    let accessToken = getAccessToken(state);

    const payload = createBookingPayload({
      formData,
      quoteReference,
      ipAddress,
      deviceFingerprint,
      deviceFingerprintError,
      clientRequestId,
      bookingChannel,
    });

    const pointsPayment = find(payload.payments, ['type', 'qff_account']);

    // For some reason occasionally tokens are expiring and we're not being notified by the qff auth
    // library. This attempts to recover from that and if it can't, presents an appropriate error
    // in place of submitting a booking that will fail.
    if (splits?.login_service?.treatment !== 'oauth' && pointsPayment && !accessToken) {
      const qffAuth = await getQffAuth();
      accessToken = qffAuth.getAccessToken(); //attempt to get the access token directly from qff auth.

      if (!accessToken) {
        qffAuth.logout();
        dispatch(
          setBooking({
            state: BOOKING_STATES.FAILED,
            errors: [
              {
                code: BOOKING_ERROR_CODES.PAYMENT_FAILED_POINTS_TOKEN_NOT_PRESENT,
              },
            ],
          }),
        );
        return;
      }
    }

    const booking = await createBookingFromApi({ payload, accessToken, qhUserId, splits });

    dispatch(setBooking(booking));
    dispatch(setBookingClientRequestId(createBookingClientRequestId())); //update the client request id after a booking has completed so a new one can be submitted

    if (booking.state === BOOKING_STATES.BOOKED) {
      dispatch(
        trackBooking({
          isGA4,
          isRebooked,
          booking,
          quote,
        }),
      );
      dispatch(
        push({
          pathname: `/bookings/${booking.id}`,
          ...(ssAction && {
            query: {
              ss_action: ssAction,
            },
          }),
        }),
      );
    } else {
      dispatch(
        checkoutError({
          isGA4,
          errors: booking.errors || [
            {
              code: BOOKING_ERROR_CODES.GENERAL_ERROR,
            },
          ],
        }),
      );
    }
  },
  onError({ error, getState }, dispatch) {
    const state = getState();
    const splits = getTreatments(state);
    dispatch(
      checkoutError({
        isGA4: splits.ga4_schema?.treatment === 'on',
        errors: error?.response?.data?.booking?.errors || [
          {
            code: BOOKING_ERROR_CODES.GENERAL_ERROR,
          },
        ],
      }),
    );
    dispatch(
      setBooking({
        state: BOOKING_STATES.FAILED,
        errors: error?.response?.data?.booking?.errors || [
          {
            code: BOOKING_ERROR_CODES.GENERAL_ERROR,
          },
        ],
      }),
    );
  },
});
