import * as client from 'lib/clients/fetchPropertyDetails';
import * as actions from 'store/property/propertyActions';
import * as errorActions from 'store/error/errorActions';
import * as splitActions from 'store/split/splitActions';
import { getPropertyId } from 'store/property/propertySelectors';
import { getQhUserId } from 'store/user/userSelectors';
import { createAsyncLogic } from 'lib/logic';
import { getBrowser } from 'store/userEnvironment/userEnvironmentSelectors';

export const fetchPropertyLogic = createAsyncLogic({
  type: actions.fetchProperty,
  latest: true,
  async process({ getState, action }, dispatch) {
    dispatch(actions.setLoading(true));
    dispatch(errorActions.clearErrors());
    let propertyId;
    const { payload } = action;
    const state = getState();
    propertyId = getPropertyId(state);
    const qhUserId = getQhUserId(state);
    const { property } = await client.fetchPropertyDetails({ propertyId, qhUserId, ...payload });

    dispatch(actions.fetchPropertySuccess({ property }));

    dispatch(
      splitActions.trackEvent({
        name: 'view_property',
        value: null,
        properties: {
          browserType: getBrowser(state).type,
        },
      }),
    );
  },
  onError({ parsedError }, dispatch) {
    dispatch(errorActions.apiRequestFailure(parsedError));
  },
  onFinally(context, dispatch) {
    dispatch(actions.setLoading(false));
  },
});
