import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Box, Link, Hide } from '@qga/roo-ui/components';
import { HeaderDesktop } from './HeaderDesktop';
import { HeaderPhone } from './HeaderPhone';
import { HeaderProvider, HeaderProviderProps } from './GlobalHeaderContext';
import { themeGet } from 'styled-system';

const StickyHeader = styled(Box)`
  top: 0;
  z-index: ${themeGet('zIndices.header')};
  background-color: ${themeGet('colors.white')};
`;

export const GlobalHeader: FC = ({ skipToContentUrl = '#content', ...rest }: HeaderProviderProps) => (
  <HeaderProvider {...rest}>
    <StickyHeader data-print-style="no-border">
      {skipToContentUrl && (
        <Link to={skipToContentUrl} hidden>
          Skip Global Header to Content
        </Link>
      )}
      <Hide xs sm>
        <HeaderDesktop {...rest} />
      </Hide>
      <Hide md lg>
        <HeaderPhone {...rest} />
      </Hide>
    </StickyHeader>
  </HeaderProvider>
);
