import { differenceInDays } from 'lib/date';
import { DEPOSIT_PAY_MINIMUM_DAYS_TO_CHECK_IN } from 'config';

export const createDepositPayAvailabilityMessage = (isLoading, checkIn, classicRewards) => {
  const daysToCheckIn = differenceInDays(checkIn, new Date());
  const isWithinMinimumCheckInPeriod = daysToCheckIn < DEPOSIT_PAY_MINIMUM_DAYS_TO_CHECK_IN;

  if (!isLoading && classicRewards) {
    return 'not available with Classic Rewards';
  }

  if (!isLoading && !classicRewards && isWithinMinimumCheckInPeriod) {
    return `only available for properties with a free cancellation policy of ${DEPOSIT_PAY_MINIMUM_DAYS_TO_CHECK_IN} days or greater`;
  }

  return null;
};
