export const interactionEvent = ({ type, value, page, customAttributes }) => {
  return {
    event: 'user_interactions',
    user_event_category: type,
    user_event_action: value,
    user_event_label: page,
    ...customAttributes,
  };
};

export const scrollTrackingEvent = ({ pixels, distance, page }) => {
  return {
    event: 'scrollTracking',
    attributes: {
      pixels,
      distance,
      label: page,
    },
  };
};
