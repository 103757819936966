import { createAction } from '@reduxjs/toolkit';
import { MenuClick } from 'types/events';

export const openPointsClubModal = createAction('ui/OPEN_POINTS_CLUB_MODAL');
export const setFullscreenGalleryContent = createAction('ui/SET_FULLSCREEN_GALLERY_CONTENT');
export const setIsPointsPay = createAction('ui/SET_IS_POINTS_PAY');
export const setIsQtaClient = createAction<boolean>('ui/SET_IS_QTA_CLIENT');
export const setIsAnzClient = createAction<boolean>('ui/SET_IS_ANZ_CLIENT');
export const setMapHoveredPropertyId = createAction('ui/SET_MAP_HOVERED_PROPERTY_ID');
export const setMapActivePropertyId = createAction('ui/SET_MAP_ACTIVE_PROPERTY_ID');
export const setDateChangeMessaging = createAction('ui/SET_DATE_CHANGE_MESSAGING');
export const clearDateChangeMessaging = createAction('ui/CLEAR_DATE_CHANGE_MESSAGING');
export const fetchAppVersion = createAction('ui/FETCH_APP_VERSION');
export const setAppVersion = createAction('ui/SET_APP_VERSION');
export const clearClient = createAction('ui/CLEAR_CLIENT');
export const menuClick = createAction<MenuClick>('ui/MENU_CLICK');
