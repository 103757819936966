import React, { FC } from 'react';
import { themeGet } from 'styled-system';
import styled from '@emotion/styled';
import { Icon, Flex } from '@qga/roo-ui/components';
import { useGlobalHeaderContext } from '../../GlobalHeaderContext';
import { formatNumber } from '../../helpers';

const UserInfoCard = styled(Flex)`
  flex-direction: column;
  background-color: ${themeGet('colors.brand.primary')};
  color: ${themeGet('colors.white')};
  align-items: center;
  font-size: ${themeGet('fontSizes.header.base')};
  padding: ${themeGet('space.5')} ${themeGet('space.2')};
`;

const Circle = styled(Flex)`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 1px white solid;
  align-items: center;
  justify-content: center;
`;

export const QffPersonalInfo: FC = () => {
  const { firstName = '', lastName = '', memberId = '', membershipTier = '', pointsBalance = '' } = useGlobalHeaderContext().qffAuthData;
  return (
    <UserInfoCard>
      <Flex mb={2}>
        <Circle>
          <Icon name="person" size={16} pb="2px" />
        </Circle>
      </Flex>
      <Flex flexWrap="wrap" textAlign="center" data-testid="full-name-member-id" data-hj-suppress>
        {firstName} {lastName} ({memberId})
      </Flex>
      <Flex data-testid="points-balance" data-hj-suppress>
        {formatNumber(pointsBalance)} points
      </Flex>
      <Flex data-testid="membership-tier" data-hj-suppress>
        {membershipTier}
      </Flex>
    </UserInfoCard>
  );
};
