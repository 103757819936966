/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, FC, ReactElement, ReactNode } from 'react';
import { noop } from 'lodash';
import { usePhoneMenuState, State, ActionDispatchers } from './menuReducer';
import { LinkOptions, QffAuthData, LogoProps } from './types';

export type InteractionInfo = {
  type: string;
  value: string;
  href?: string;
};

export type OnInteractionCallback = (info: InteractionInfo) => any;

export type GlobalHeaderContextType = Omit<HeaderProviderProps, 'children'> & State & ActionDispatchers;

export const GlobalHeaderContext = createContext<GlobalHeaderContextType>({
  desktopLinks: [],
  phoneLinks: [],
  qffLinks: [],
  qffAuthData: { isAuthenticated: false },
  isNavMenuOpen: false,
  isQffMenuOpen: false,
  toggleNavMenu: noop,
  toggleQffMenu: noop,
  onClickLogin: noop,
  onClickLogout: noop,
  onInteraction: noop,
  onDismissLoginTooltip: noop,
  displayLoginTooltip: true,
  queryString: undefined,
});

export interface HeaderProviderProps {
  skipToContentUrl?: string;
  desktopLinks: LinkOptions[];
  phoneLinks: LinkOptions[];
  qffLinks: LinkOptions[];
  qffAuthData: QffAuthData;
  onClickLogin: () => any;
  onClickLogout: () => any;
  onInteraction: OnInteractionCallback;
  onDismissLoginTooltip?: () => any;
  displayLoginTooltip?: boolean;
  queryString?: string;
  universalNavEnabled?: boolean;
  qffLoginEnabled?: boolean;
  homepageUrl?: string;
  homepageLogoSrc?: string;
  homepageLogoLabel?: string;
  LogoItemRenderer?: (props: LogoProps) => JSX.Element;
  arrowDirection?: string[] | string;
  children: ReactNode | ReactElement;
}

export const HeaderProvider: FC<HeaderProviderProps> = ({
  children,
  desktopLinks,
  phoneLinks,
  qffAuthData,
  qffLinks,
  onDismissLoginTooltip,
  displayLoginTooltip,
  queryString,
  ...rest
}: HeaderProviderProps) => {
  const [state, actions] = usePhoneMenuState();

  const callbacks = useRef(rest);

  useEffect(() => {
    callbacks.current = rest;
  });

  const onClickLogin = useCallback(() => callbacks.current.onClickLogin(), []);
  const onClickLogout = useCallback(() => callbacks.current.onClickLogout(), []);
  const onInteraction = useCallback((info: InteractionInfo) => callbacks.current.onInteraction(info), []);

  const contextValue = useMemo(
    () =>
      ({
        ...state,
        ...actions,
        desktopLinks,
        phoneLinks,
        qffAuthData,
        qffLinks,
        onClickLogin,
        onClickLogout,
        onInteraction,
        onDismissLoginTooltip,
        displayLoginTooltip,
        queryString,
      }) as GlobalHeaderContextType,
    [
      state,
      actions,
      desktopLinks,
      phoneLinks,
      qffAuthData,
      qffLinks,
      onClickLogin,
      onClickLogout,
      onInteraction,
      onDismissLoginTooltip,
      displayLoginTooltip,
      queryString,
    ],
  );

  return <GlobalHeaderContext.Provider value={contextValue}>{children}</GlobalHeaderContext.Provider>;
};

export const useGlobalHeaderContext = () => useContext(GlobalHeaderContext);
