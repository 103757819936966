import { isIOSDevice } from 'lib/browser';

export const unfixBodyScroll = () => {
  const bodyEl = document.querySelector('body');

  if (!isIOSDevice()) {
    bodyEl.style.overflow = 'visible';
  }

  if (isIOSDevice()) {
    bodyEl.style.position = 'initial';
    bodyEl.style.left = 'initial';
    bodyEl.style.right = 'initial';
    bodyEl.style.top = 'initial';
    bodyEl.style.bottom = 'initial';

    if (bodyEl.hasAttribute('data-scroll-offset')) {
      window.scrollTo(0, bodyEl?.getAttribute('data-scroll-offset'));
      bodyEl.removeAttribute('data-scroll-offset');
    }
  }
};
