import * as client from 'lib/clients/fetchContent';
import * as actions from 'store/propertyExclusiveOffersPage/propertyExclusiveOffersPageActions';
import { createAsyncLogic } from 'lib/logic';
import { apiRequestFailure } from 'store/error/errorActions';

export const fetchPropertyExclusiveOffersPageLogic = createAsyncLogic({
  type: actions.fetchPropertyExclusiveOffersPage,
  latest: true,
  async process({ action }, dispatch) {
    const { payload } = action;
    const propertyExclusiveOffersPage = await client.fetchContent({ ...payload, type: 'propertyExclusiveOffersPage' });
    dispatch(actions.setPropertyExclusiveOffersPage(propertyExclusiveOffersPage));
  },
  onError({ parsedError }, dispatch) {
    dispatch(apiRequestFailure(parsedError));
  },
});
