import AppLink from 'components/AppLink';
import { HOTEL_DEALS_URL, HOLIDAY_DEALS, BRAND_SELF_SERVICE_URL, FAQS } from './constants.js';
import { HOTELS_URL } from 'config/env';
import { CONTACT_US } from './brand';

const getDestinationStates = (gclid) => {
  return [
    {
      children: 'Queensland',
      href: `/hotels/australia/qld?gclid=${gclid}`,
      trackingName: 'Queensland Destination',
    },
    {
      children: 'New South Wales',
      href: `/hotels/australia/nsw?gclid=${gclid}`,
      trackingName: 'New South Wales Destination',
    },
    {
      children: 'Victoria',
      href: `/hotels/australia/vic?gclid=${gclid}`,
      trackingName: 'Victoria Destination',
    },
    {
      children: 'Western Australia',
      href: `/hotels/australia/wa?gclid=${gclid}`,
      trackingName: 'Western Australia Destination',
    },
    {
      children: 'Northern Territory',
      href: `/hotels/australia/nt?gclid=${gclid}`,
      trackingName: 'Northern Territory Destination',
    },
    {
      children: 'South Australia',
      href: `/hotels/australia/sa?gclid=${gclid}`,
      trackingName: 'South Australia Destination',
    },
    {
      children: 'Tasmania',
      href: `/hotels/australia/tas?gclid=${gclid}`,
      trackingName: 'Tasmania Destination',
    },
    {
      children: 'Australian Capital Territory',
      href: `/hotels/australia/act?gclid=${gclid}`,
      trackingName: 'Australian Capital Territory Destination',
    },
  ];
};

const getDestinationCities = (gclid) => {
  return [
    {
      children: 'Melbourne',
      'data-testid': 'melbourne-destinations-link',
      href: `/hotels/australia/vic/melbourne?gclid=${gclid}`,
      trackingName: 'Melbourne Destination',
    },
    {
      children: 'Sydney',
      'data-testid': 'sydney-destinations-link',
      href: `/hotels/australia/nsw/sydney?gclid=${gclid}`,
      trackingName: 'Sydney Destination',
    },
    {
      children: 'Brisbane',
      'data-testid': 'brisbane-destinations-link',
      href: `/hotels/australia/qld/brisbane?gclid=${gclid}`,
      trackingName: 'Brisbane Destination',
    },
    {
      children: 'Perth',
      'data-testid': 'perth-destinations-link',
      href: `/hotels/australia/wa/perth?gclid=${gclid}`,
      trackingName: 'Perth Destination',
    },
    {
      children: 'Adelaide',
      'data-testid': 'adelaide-destinations-link',
      href: `/hotels/australia/sa/adelaide?gclid=${gclid}`,
      trackingName: 'Adelaide Destination',
    },
    {
      children: 'Darwin',
      'data-testid': 'darwin-destinations-link',
      href: `/hotels/australia/nt/darwin?gclid=${gclid}`,
      trackingName: 'Darwin Destination',
    },
    {
      children: 'Cairns',
      'data-testid': 'cairns-destinations-link',
      href: `/hotels/australia/qld/cairns?gclid=${gclid}`,
      trackingName: 'Cairns Destination',
    },
    {
      children: 'Surfers Paradise',
      'data-testid': 'surfers-paradise-destinations-link',
      href: `/hotels/australia/qld/surfers-paradise?gclid=${gclid}`,
      trackingName: 'Surfers Paradise Destination',
    },
  ];
};

const getNavigationDropDown = (gclid) => {
  return [
    {
      children: 'Australia',
      href: `/hotels/australia?gclid=${gclid}`,
      trackingName: 'Australia Destination',
      submenu: getDestinationCities(gclid),
    },
    {
      children: 'By State',
      trackingName: 'By State Destination',
      submenu: getDestinationStates(gclid),
    },
  ];
};

export const getDesktopNavigationMenuGclid = (gclid) => {
  return [
    {
      trackingName: 'Hotels',
      href: `/search/list?gclid=${gclid}`,
      'data-testid': 'hotels-link',
      children: 'Hotels',
      usesQueryParams: true,
      hasGclid: true,
    },
    {
      trackingName: 'Destinations',
      'data-testid': 'destinations-desktop-link',
      children: 'Destinations',
      dropdown: getNavigationDropDown(gclid),
    },
    {
      trackingName: 'Hotel Deals',
      'data-testid': 'deals-link',
      children: 'Hotel Deals',
      href: HOTEL_DEALS_URL,
    },
    {
      trackingName: 'Luxury Holidays',
      'data-testid': 'luxe-link',
      children: 'Luxury Holidays',
      href: HOLIDAY_DEALS,
    },
    {
      trackingName: 'Airbnb',
      'data-testid': 'airbnb-link',
      children: 'Airbnb',
      href: `${HOTELS_URL}/airbnb`,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  ];
};

const getSupportLinks = (gclid) => {
  return [
    {
      trackingName: 'Manage Hotels Bookings',
      href: BRAND_SELF_SERVICE_URL,
      'data-testid': 'manage-hotels-bookings',
      children: 'Manage Hotels Bookings',
    },
    {
      trackingName: 'FAQs',
      'data-testid': 'phone-faqs-link',
      children: 'FAQs',
      to: `${FAQS}?gclid=${gclid}`,
      as: AppLink,
    },
    {
      trackingName: 'Contact',
      'data-testid': 'phone-contact-link',
      children: 'Contact',
      to: `${CONTACT_US}?gclid=${gclid}`,
      as: AppLink,
    },
  ];
};

export const getFooterNavigationLinksGclid = (gclid) => {
  return [
    {
      title: 'Hotels',
      linkList: getDesktopNavigationMenuGclid(gclid),
    },
    {
      title: 'Support',
      linkList: getSupportLinks(gclid),
    },
    {
      title: 'Destination',
      linkList: getDestinationStates(gclid),
    },
    {
      linkList: getDestinationCities(gclid),
    },
  ];
};
