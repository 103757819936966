import React, { FC, Fragment } from 'react';
import { Text, OutlineButton } from '@qga/roo-ui/components';
import { useGlobalHeaderContext } from '../GlobalHeaderContext';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { LogOutButton } from './primitives';
import { QffLinks } from './QffLinks';
import { QffPersonalInfo } from './QffPersonalInfo';

const LogoutButton = styled(OutlineButton)`
  margin: 0 ${themeGet('space.5')};
  width: 100%;
`;

export const QffMenuContent: FC = () => {
  const { onClickLogout, onInteraction, toggleQffMenu } = useGlobalHeaderContext();

  const handleLogout = () => {
    onInteraction({ type: 'QFF Login Dropdown', value: 'Log Out Selected' });
    toggleQffMenu(false);
    onClickLogout();
  };

  return (
    <Fragment>
      <Text fontSize="header.base" pl={5} py={5}>
        Frequent Flyer Login
      </Text>
      <QffPersonalInfo />
      <QffLinks />
      <LogOutButton>
        <LogoutButton onClick={handleLogout} variant="primary" data-testid="logout-button">
          LOG OUT
        </LogoutButton>
      </LogOutButton>
    </Fragment>
  );
};
