import wrappedAxios from './wrappedAxios';
import { HOTELS_API_URL } from 'config';

export type FetchExclusiveOfferArg = { cookie?: string | Record<string, string>; propertyId: string; previewToken?: string };

export const fetchExclusiveOffer = async ({ propertyId, previewToken, cookie }: FetchExclusiveOfferArg) => {
  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/exclusive-offers/${propertyId}${previewToken ? '/preview' : ''}`,
    method: 'GET',
    cookie,
    ...(previewToken && {
      headers: {
        'x-sanity-api-key': previewToken,
      },
    }),
  });

  return response.data;
};
