/* eslint-disable react/prop-types */
import React, { FC } from 'react';
import { compact } from 'lodash';
import { Flex, Icon, Text as BaseText, TextProps } from '@qga/roo-ui/components';
import { useGlobalHeaderContext } from '../../../GlobalHeaderContext';
import { formatNumber } from '../../../helpers';
import { QffAuthData } from '../../../types';

type MemoProps = QffAuthData;
const Text: FC<TextProps> = (props) => <BaseText fontSize="header.sm" fontWeight="bold" {...props} />;

const QffAuthDetailsMemo: FC<MemoProps> = ({ isAuthenticated, title, firstName, lastName, membershipTier, pointsBalance }: MemoProps) => {
  if (!isAuthenticated) return null;

  return (
    <Flex alignItems="center" data-testid="auth-details-wrapper">
      <Flex flexDirection="column" color="white" justifyContent="flex-start" ml={2}>
        <Text fontSize="header.sm" fontWeight="bold" data-hj-suppress pr={2} data-testid="member-name">
          {compact([title, firstName, lastName]).join(' ')}
        </Text>
        <Flex>
          <Text data-testid="member-points-balance">{formatNumber(pointsBalance || 0)} points</Text>
          <Text mx={1}>-</Text>
          <Text data-testid="member-tier" data-hj-suppress>
            {membershipTier}
          </Text>
        </Flex>
      </Flex>
      <Icon name="expandMore" ml={4} />
    </Flex>
  );
};

export const QffAuthDetails = () => {
  const { qffAuthData } = useGlobalHeaderContext();
  return <QffAuthDetailsMemo {...qffAuthData} />;
};
