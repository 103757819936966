import { createReducer } from '@reduxjs/toolkit';
import { EMAIL_ENQUIRY_STATUS } from 'lib/enums/contactUs';
import { clearEnquiry, setEnquiry, clearCallbackRequest, setCallbackRequest } from './enquiryActions';

const initialState = {
  error: null,
  enquiry: null,
  callbackRequest: null,
  status: EMAIL_ENQUIRY_STATUS.READY,
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(clearEnquiry, (state) => {
      state.error = initialState.error;
      state.enquiry = initialState.enquiry;
      state.status = initialState.status;
    })
    .addCase(setEnquiry, (state, { payload }) => {
      state.callbackRequest = payload.callbackRequest;
      state.enquiry = payload.enquiry;
      state.error = payload.error;
      state.status = payload.status;
    })
    .addCase(clearCallbackRequest, (state) => {
      state.callbackRequest = null;
    })
    .addCase(setCallbackRequest, (state, { payload }) => {
      state.callbackRequest = payload;
    }),
);
