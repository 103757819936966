import { createReducer } from '@reduxjs/toolkit';
import { clearPointsBurnLuxeTiers, setPointsBurnLuxeTiers } from './pointsBurnLuxeActions';

const initialState: { levels: Record<string, unknown>[]; name: string; id: string } = {
  levels: [],
  name: '',
  id: '',
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setPointsBurnLuxeTiers, (state, { payload }) => {
      state.levels = payload?.levels;
      state.name = payload?.name;
      state.id = payload?.id;
    })
    .addCase(clearPointsBurnLuxeTiers, (state) => {
      state.levels = [];
      state.name = '';
      state.id = '';
    }),
);
