import * as z from 'zod';
import { boolean, brand, shortDate } from './generic';

// #region - AVA /calendar/property

// const getAvaPropertyAvailabilityByCalendarParams = z.object({
//   start_date: z.string(),
//   end_date: z.string(),
//   property_id: z.string(),
//   luxe_only: z.boolean(),
// });

// const getAvaPropertyAvailabilityByCalendarResponse = z.object({
//   meta: z.object({
//     query: z.object({
//       children: z.string(),
//       offer_id: z.string(),
//       property_id: z.string(),
//       channel: z.string(),
//       pay_with: z.string(),
//       brand: brand,
//       nights: z.string(),
//       year: z.string(),
//       end_date: z.string(),
//       start_date: z.string(),
//       user_groups: z.string(),
//       infants: z.string(),
//       adults: z.string(),
//     }),
//   }),
//   stays: z.array(
//     z.object({
//       check_in: z.string(),
//       available: z.boolean(),
//       offer: z
//         .object({
//           charges: z.object({
//             total: z.object({
//               amount: z.string(),
//               points: z.number(),
//               currency: z.string(),
//             }),
//           }),
//         })
//         .nullable(),
//     }),
//   ),
// });

// #endregion

// #region - QH_API /exclusive-offers/:propertyId/availability

export const getAvaPropertyAvailabilityByCalendarParams = z.object({
  startDate: shortDate,
  endDate: shortDate,
  propertyId: z.string(),
  offerId: z.string().optional(),
  luxeOnly: z.boolean().or(boolean).optional(),
});

export const getAvaPropertyAvailabilityByCalendarResponse = z.object({
  meta: z.object({
    query: getAvaPropertyAvailabilityByCalendarParams.required({ luxeOnly: true }),
    calendarAvailable: z.boolean(),
  }),
  stays: z.array(
    z.object({
      date: shortDate,
      minStay: z.number(),
      available: z.boolean(),
    }),
  ),
});

// #endregion

// #region - AVA /calendar/offer

// const getAvaOfferAvailabilityByCalendarParams = z.object({
//   start_date: z.string(), // date?
//   end_date: z.string(), // date?
//   property_id: z.string(),
//   offer_id: z.string(),
//   infants: z.number().optional(), // technically string?
//   children: z.number().optional(), // technically string?
//   adults: z.number().optional(), // technically string?
//   nights: z.number().optional(), // technically string?
//   user_groups: z.string().optional(), // array -> string?
//   channel: z.string().optional(),
// });

// const getAvaOfferAvailabilityByCalendarResponse = z.object({
//   meta: z.object({
//     query: z.object({
//       property_id: z.string(),
//       start_date: z.string(),
//       end_date: z.string(),
//       luxe_only: z.boolean(),
//     }),
//     calendar_available: z.boolean(),
//   }),
//   stays: z.array(
//     z.object({
//       date: z.string(),
//       'min-stay': z.number(),
//       available: z.boolean(),
//     }),
//   ),
// });

// #endregion

// #region - QH_API /exclusive-offers/:propertyId/availability/:offerId

export const getAvaOfferAvailabilityByCalendarParams = z.object({
  startDate: shortDate,
  endDate: shortDate,
  propertyId: z.string(),
  offerId: z.string(),
  infants: z.number().optional(), // technically string?
  children: z.number().optional(), // technically string?
  adults: z.number().optional(), // technically string?
  nights: z.number().optional(), // technically string?
  userGroups: z.string().optional(), // array -> string?
  channel: z.string().optional(),
});

export const getAvaOfferAvailabilityByCalendarResponse = z.object({
  meta: z.object({
    query: z.object({
      children: z.string(),
      offerId: z.string(),
      propertyId: z.string(),
      channel: z.string(),
      payWith: z.string(),
      brand: brand,
      nights: z.string(),
      year: z.string(),
      endDate: shortDate,
      startDate: shortDate,
      userGroups: z.string(),
      infants: z.string(),
      adults: z.string(),
    }),
  }),
  stays: z.array(
    z.object({
      checkIn: shortDate,
      available: z.boolean(),
      offer: z
        .object({
          charges: z.object({
            total: z.object({
              amount: z.string(),
              points: z.number(),
              currency: z.string(),
            }),
          }),
        })
        .nullable(),
    }),
  ),
});

// #endregion
