import { setIsQtaClient, setIsAnzClient } from 'store/ui/uiActions';

const loadClientType = async ({ store, query }) => {
  const clientType = query?.client;

  store.dispatch(setIsQtaClient(clientType === 'qta'));
  // Update the url -without- the 'client' query string

  store.dispatch(setIsAnzClient(clientType === 'anz'));
};

export default loadClientType;
