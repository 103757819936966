import { format as FechaFormat } from 'fecha';
import { LocalDate } from './localDate';

export const toLocalDate = (date) => {
  if (date instanceof LocalDate) {
    return date;
  }
  return LocalDate.fromString(date);
};

export const format = (date, formatting) => {
  const d = toLocalDate(date);
  const jsDate = new Date(d.year, d.month - 1, d.day);
  return FechaFormat(jsDate, formatting);
};
