import { createReducer } from '@reduxjs/toolkit';
import {
  clearQuoteDepositPay,
  createQuoteDepositPaySuccess,
  createQuoteDepositPayFailure,
  createQuoteDepositPay,
  setQuoteCreated,
} from './quoteDepositPayActions';

const initialState = {
  quote: null,
  quoteCreated: false,
  error: null,
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(createQuoteDepositPay, (state) => {
      state.error = null;
    })
    .addCase(createQuoteDepositPaySuccess, (state, { payload }) => {
      state.quote = payload.quote;
    })
    .addCase(createQuoteDepositPayFailure, (state, { payload }) => {
      state.error = payload;
      state.quote = null;
    })
    .addCase(clearQuoteDepositPay, (state) => {
      state.quote = null;
    })
    .addCase(setQuoteCreated, (state) => {
      state.quoteCreated = true;
    }),
);
