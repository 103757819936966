import { Dispatch, useMemo, useReducer } from 'react';

export interface State {
  isNavMenuOpen: boolean;
  isQffMenuOpen: boolean;
}

type Action = { type: 'toggleNavMenu'; payload?: boolean } | { type: 'toggleQffMenu'; payload?: boolean };

const initialState: State = {
  isNavMenuOpen: false,
  isQffMenuOpen: false,
};

function phoneMenuReducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case 'toggleNavMenu':
      return {
        ...state,
        isNavMenuOpen: action.payload ?? !state.isNavMenuOpen,
        isQffMenuOpen: false,
      };
    case 'toggleQffMenu':
      return {
        ...state,
        isNavMenuOpen: false,
        isQffMenuOpen: action.payload ?? !state.isQffMenuOpen,
      };
    default:
      return state;
  }
}

function createActions(dispatch: Dispatch<Action>) {
  return {
    toggleNavMenu: (payload?: boolean) => dispatch({ type: 'toggleNavMenu', payload }),
    toggleQffMenu: (payload?: boolean) => dispatch({ type: 'toggleQffMenu', payload }),
  };
}

export function usePhoneMenuState() {
  const [state, dispatch] = useReducer(phoneMenuReducer, initialState);

  const actions = useMemo(() => createActions(dispatch), [dispatch]);

  return [state, actions] as const;
}

export type ActionDispatchers = ReturnType<typeof createActions>;
