import { emitPageView } from 'store/userEnvironment/userEnvironmentActions';
import { EventType, SiteId, createEvent } from '@qantasexperiences/analytics';

const emitLocationChangeEvent = ({ payload }) => {
  if (payload.isGA4) {
    return createEvent({
      type: EventType.PAGE_VIEW,
      payload: {
        type: payload.pageType,
        title: document.title,
        location: location.href,
        referrer: document.referrer,
        siteId: SiteId.HOTELS,
        userId: payload.qh_user_id,
        isLoggedIn: payload.isLoggedIn,
        ...(payload.isLoggedIn && {
          qffPoints: payload.qffPoints,
          qffTier: payload.qffTier,
          qffHashId: payload.qf_value,
        }),
      },
    });
  }

  return {
    event: 'Page Variables Ready',
    ...payload,
  };
};

export default { [emitPageView]: emitLocationChangeEvent };
