import styled from '@emotion/styled';
import { Flex, Box, NakedButton } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';

export const QffMenuButtonWrapper = styled(Box)`
  cursor: pointer;
`;

export const QffMenuWrapper = styled(Flex)<{ translateY: number }>`
  position: fixed;
  top: 74px;
  right: 0;
  left: 0;
  bottom: 0;
  flex-direction: column;
  will-change: transform;
  transition: all 0.2s ease-in-out;
  background-color: ${themeGet('colors.white')};
  z-index: ${themeGet('zIndices.phoneMenuDrawerClose')};
  border-top: ${themeGet('space.2')} solid ${themeGet('colors.brand.primary')};

  transform: translateY(${(props) => props.translateY}px);

  ${themeGet('mediaQueries.1')} {
    transition: null;
    width: 35%;
    left: auto;
    bottom: auto;
  }
`;

export const RedBox = styled(NakedButton)`
  height: 74px;
  width: 80px;
  cursor: pointer;
  padding: ${themeGet('space.4')} 0;
  background-color: ${themeGet('colors.brand.primary')};
  color: ${themeGet('colors.white')};
  align-items: center;
  justify-content: center;
`;
