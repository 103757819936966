import styled from '@emotion/styled';
import { Text, Link } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';

export const MembershipTier = styled(Text)`
  font-size: ${themeGet('fontSizes.header.sm')};
  font-weight: ${themeGet('fontWeights.bold')};
`;

export const StyledText = styled(Text)`
  font-size: ${themeGet('fontSizes.header.sm')};
  font-weight: bold;
`;

export const JoinNowText = styled(Text)`
  color: ${themeGet('colors.white')};
  &:hover {
    color: ${themeGet('colors.white')};
  }
`;

export const JoinNowLink = styled(Link)`
  display: block;
  padding-right: ${themeGet('space.4')};
`;
