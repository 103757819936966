import { createReducer } from '@reduxjs/toolkit';
import { clearExclusiveOffer, fetchExclusiveOffer, setExclusiveOffer, setLoading, setExclusiveOfferList } from './exclusiveOfferActions';
import { STORE_STATUS } from 'lib/enums/store';
import { ExclusiveOffer } from 'types/content';

export type ExclusiveOfferState = {
  exclusiveOffer: ExclusiveOffer | null;
  error: null;
  isLoading: boolean;
  status: (typeof STORE_STATUS)[keyof typeof STORE_STATUS];
  exclusiveOffersList: unknown[];
};

const initialState: ExclusiveOfferState = {
  exclusiveOffer: null,
  error: null,
  isLoading: false,
  status: STORE_STATUS.PENDING,
  exclusiveOffersList: [],
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(fetchExclusiveOffer, (state) => {
      state.status = STORE_STATUS.PENDING;
    })
    .addCase(setExclusiveOffer, (state, { payload }) => {
      state.error = null;
      state.exclusiveOffer = payload;
      state.status = STORE_STATUS.RESOLVED;
    })
    .addCase(clearExclusiveOffer, (state) => {
      state.exclusiveOffer = null;
      state.error = null;
      state.isLoading = false;
      state.status = STORE_STATUS.PENDING;
      state.exclusiveOffersList = [];
    })
    .addCase(setLoading, (state, { payload }) => {
      state.isLoading = payload;
    })
    .addCase(setExclusiveOfferList, (state, { payload }) => {
      state.exclusiveOffersList = payload;
    }),
);
