import { createReducer } from '@reduxjs/toolkit';
import {
  clearHotelsAndAirbnbTermsAndConditions,
  fetchHotelsAndAirbnbTermsAndConditions,
  setHotelsAndAirbnbTermsAndConditions,
} from './hotelsAndAirbnbTermsAndConditionsActions';
import { STORE_STATUS } from 'lib/enums/store';

const initialState = {
  hotelsAndAirbnbTermsAndConditions: null,
  error: null,
  status: STORE_STATUS.PENDING,
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(fetchHotelsAndAirbnbTermsAndConditions, (state) => {
      state.status = STORE_STATUS.PENDING;
    })

    .addCase(setHotelsAndAirbnbTermsAndConditions, (state, { payload }) => {
      state.error = null;
      state.status = STORE_STATUS.RESOLVED;
      state.hotelsAndAirbnbTermsAndConditions = payload;
    })

    .addCase(clearHotelsAndAirbnbTermsAndConditions, (state) => {
      state.hotelsAndAirbnbTermsAndConditions = null;
      state.error = null;
      state.status = STORE_STATUS.PENDING;
    }),
);
