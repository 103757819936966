import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import { fetchTreatments, setTreatments } from 'store/split/splitActions';
import { getQhUserId } from 'store/user/userSelectors';
import { getSplitAttributes } from 'store/split/splitSelectors';
import { createAsyncLogic } from 'lib/logic';

const mergeWithFallbackTreatment = (splitTreatments) =>
  reduce(
    splitTreatments,
    (accum, treatment, treatmentName) => {
      const treatmentValue = treatment ?? {
        treatment: 'control',
        config: null,
      };

      return {
        ...accum,
        [treatmentName]: treatmentValue,
      };
    },
    {},
  );

export const fetchTreatmentsLogic = createAsyncLogic({
  type: fetchTreatments,
  debounce: 10,
  async process({ getState, splitClient }, dispatch, done) {
    const state = getState();
    const currentSplitTreatments = get(state, 'split.treatments');
    const splitsToFetch = Object.keys(currentSplitTreatments);
    const qhUserId = getQhUserId(state);
    let updatedSplitTreatments;
    let splitAttributes;

    if (!isEmpty(splitsToFetch)) {
      splitAttributes = getSplitAttributes(getState());

      try {
        // splitClient is local object created by createBrowserSplitClient(), not the splitio SDK client
        updatedSplitTreatments = await splitClient.getTreatments(splitsToFetch, splitAttributes, qhUserId);
      } catch (_) {
        updatedSplitTreatments = mergeWithFallbackTreatment(currentSplitTreatments);
      } finally {
        if (!isEqual(updatedSplitTreatments, currentSplitTreatments)) {
          dispatch(setTreatments(updatedSplitTreatments));
        }
        done();
      }
    }
  },
});
