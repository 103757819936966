import { EventType, createEvent } from '@qantasexperiences/analytics';
import { searchSuggestionClick } from 'store/homePage/homePageActions';

const emitSearchSuggestionClickEvent = ({ payload }) => {
  return createEvent({
    type: EventType.SEARCH_SUGGESTION_CLICK,
    payload: {
      componentVariant: payload.componentVariant,
      itemId: payload.itemId,
      itemName: payload.itemName,
      itemText: payload.itemText,
      index: payload.index,
      url: payload.url,
      count: payload.count,
      searchRecent: payload.searchRecent,
      viewMode: payload.viewMode,
    },
  });
};

// eslint-disable-next-line
// @ts-ignore
export default { [searchSuggestionClick]: emitSearchSuggestionClickEvent };
