import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import loadClientType from 'server/lib/populateReduxStore/handlers/loadClientType';

const ClientsideReduxUpdater: FC = () => {
  const [hasInitialized, setInitialized] = useState(false);
  const dispatch = useDispatch();
  const { query, isReady } = useRouter() || {};

  // Update isMobileApp when router first initializes and then persist state between renders
  useEffect(() => {
    if (isReady && !hasInitialized) {
      setInitialized(true);
      loadClientType({ store: { dispatch }, query });
    }
  }, [query, dispatch, isReady, hasInitialized]);

  return null;
};

export default ClientsideReduxUpdater;
