import { createSelector } from '@reduxjs/toolkit';
import { createDepositPayAvailabilityMessage } from 'lib/search/createDepositPayAvailabilityMessage';
import { getQueryCheckIn, getQueryClassicRewards } from 'store/router/routerSelectors';
import { getIsLoading } from './searchSelectors';

export const getDepositPayAvailabilityMessage = createSelector(
  getIsLoading,
  getQueryCheckIn,
  getQueryClassicRewards,
  createDepositPayAvailabilityMessage,
);
