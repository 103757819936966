import Decimal from 'decimal.js';
/*
  Note: Almost all process.env.* references must be prefixed with "RAZZLE_".
  See https://github.com/jaredpalmer/razzle#environment-variables
*/

// --ENV
export const ENVIRONMENT = process.env.ENVIRONMENT || process.env.NEXT_PUBLIC_ENVIRONMENT;
export const VERSION = process.env.NEXT_PUBLIC_VERSION;
export const PUBLIC_PATH = process.env.NEXT_PUBLIC_PUBLIC_PATH;

export const IS_DEVELOPMENT = ['development', 'ted'].includes(ENVIRONMENT);
export const IS_PRODUCTION = ENVIRONMENT === 'production';
export const IS_SERVER = typeof window === 'undefined';

export const SERVER_HOTELS_API_URL = `${process.env.NEXT_PUBLIC_HOTELS_API_HOST}${process.env.NEXT_PUBLIC_HOTELS_API_BASE_PATH}`;

export const HOTELS_API_URL = IS_SERVER ? SERVER_HOTELS_API_URL : process.env.NEXT_PUBLIC_HOTELS_API_BASE_PATH;

export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
export const QFF_LOGIN_BUNDLE_URL = process.env.NEXT_PUBLIC_QFF_LOGIN_BUNDLE_URL;
export const SIMPLICITY_BUNDLE_URL = process.env.NEXT_PUBLIC_SIMPLICITY_BUNDLE_URL;
export const LOG_ANALYTICS = process.env.NEXT_PUBLIC_LOG_ANALYTICS;
export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
export const GTM_AUTH = process.env.NEXT_PUBLIC_GTM_AUTH;
export const GTM_PREVIEW = process.env.NEXT_PUBLIC_GTM_PREVIEW;
export const GOOGLE_MAPS_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;
export const GOOGLE_MAPS_STATIC_API_URL = process.env.NEXT_PUBLIC_GOOGLE_MAPS_STATIC_API_URL;
export const HOTELS_HOST = process.env.NEXT_PUBLIC_HOTELS_HOST || 'https://www.qantas.com';
export const HOTELS_PATH = '/hotels';
export const HOTELS_HOMEPAGE_PATH = HOTELS_PATH;
export const HOTELS_URL = `${HOTELS_HOST}${HOTELS_PATH}`;
export const HOLIDAYS_PATH = '/holidays';
export const HOLIDAYS_URL = `${HOTELS_HOST}${HOLIDAYS_PATH}`;
export const AUTH_API_URL = process.env.NEXT_PUBLIC_AUTH_API_URL;
export const LSL_AUTH_URL = process.env.NEXT_PUBLIC_LSL_AUTH_URL;
export const PREVIOUS_TIER_NAME = process.env.NEXT_PUBLIC_PREVIOUS_TIER_NAME;

export const SPLIT_BROWSER_REFRESH_RATE_SECS = process.env.NEXT_PUBLIC_SPLIT_BROWSER_REFRESH_RATE_SECS;
export const SNARE_JS_URL = process.env.NEXT_PUBLIC_SNARE_JS_URL;
export const ADYEN_API_VERSION = process.env.NEXT_PUBLIC_ADYEN_API_VERSION;
export const ADYEN_MERCHANT_ID = process.env.NEXT_PUBLIC_ADYEN_MERCHANT_ID;
export const ADYEN_CLIENT_KEY = process.env.NEXT_PUBLIC_ADYEN_CLIENT_KEY;
export const ADYEN_ENVIRONMENT = process.env.NEXT_PUBLIC_ADYEN_ENVIRONMENT;
export const ADYEN_QEPG_CLIENT_KEY = process.env.NEXT_PUBLIC_ADYEN_QEPG_CLIENT_KEY;
export const BOOKING_CONFIRMATION_NPS_SURVEY_URL = process.env.NEXT_PUBLIC_BOOKING_CONFIRMATION_NPS_SURVEY_URL;
export const SALESFORCE_ORG_ID = process.env.NEXT_PUBLIC_SALESFORCE_ORG_ID;
export const SALESFORCE_DEPLOYMENT_ID = process.env.NEXT_PUBLIC_SALESFORCE_DEPLOYMENT_ID;
export const SALESFORCE_LIVE_CHAT_ID = process.env.NEXT_PUBLIC_SALESFORCE_LIVE_CHAT_ID;
export const RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
export const LUXE_HOST = process.env.NEXT_PUBLIC_LUXE_HOST;
export const HOLIDAY_PACKAGES_HOST = process.env.NEXT_PUBLIC_HOLIDAY_PACKAGES_HOST;

export const SANITY_PROJECT_ID = process.env.NEXT_PUBLIC_SANITY_PROJECT_ID || 'g1i9e5jz';
export const SANITY_DATASET_NAME = process.env.NEXT_PUBLIC_SANITY_DATASET_NAME || 'admin-ui-stg';
export const SANITY_API_VERSION = process.env.NEXT_PUBLIC_SANITY_API_VERSION || '2021-06-01';

export const OPTIMIZELY_DATAFILE = process.env.NEXT_PUBLIC_OPTIMIZELY_DATAFILE;

// --Direct config
export const SEARCH_DATE_FORMAT = 'yyyy-MM-dd';
export const DISPLAY_DATE_FORMAT = 'EEE d MMM, yyyy';
export const CARD_DATE_FORMAT = 'd MMM';
export const LIST_SEARCH_LIMIT = 20;
export const LIST_DEALS_LIMIT = 3;
export const DEPOSIT_PAY_MINIMUM_DAYS_TO_CHECK_IN = 21;
export const MOBILE_MAP_SEARCH_LIMIT = 100;
export const DESKTOP_MAP_SEARCH_LIMIT = 50;
export const AUTH_REFRESH_INTERVAL = 90000; // refresh auth state to stay logged in
export const TRIPADVISOR_PARTNER_ID = '0FD9767022B94B9F91BC63981C331B18';
export const MIN_POINTS_AMOUNT = new Decimal(5000);
export const DEPOSIT_PAY_PERCENTAGE = new Decimal(20);
export const SALESFORCE_LIVE_CHAT_HOST = 'https://d.la1-c2-ukb.salesforceliveagent.com';
export const RECAPTCHA_JS = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;

export const ISR_REVALIDATE_IN_SECONDS = 60;
