/* eslint-disable react/display-name */
import React, { Fragment, FC, useEffect, useState } from 'react';
import { Box, Text, Icon, NakedButton } from '@qga/roo-ui/components';
import { Drawer, DrawerClose, MenuBtn, PhoneMenuLink } from './primitives';
import { QantasUniversalNav } from '../../QantasUniversalNav';
import { QffAuthDetails } from './QffAuthDetails';
import { GlobalHeaderContextType, InteractionInfo, useGlobalHeaderContext } from '../../GlobalHeaderContext';
import { fixBodyScroll, unfixBodyScroll } from '../../../../lib/browser';
import { HOTELS_URL } from 'config/env';
import useMenuClickEvent from 'hooks/useMenuClickEvent';
import { isEmpty } from 'lodash';

type PhoneLinksProps = Pick<GlobalHeaderContextType, 'phoneLinks' | 'onInteraction'>;

const PhoneLinks = React.memo(({ phoneLinks, onInteraction }: PhoneLinksProps) => {
  const { menuClickEvent } = useMenuClickEvent();

  return (
    <>
      {phoneLinks.map(({ trackingName, ...link }) => (
        <PhoneMenuLink
          key={trackingName}
          onClick={() => {
            onInteraction({
              type: 'Site Navigation',
              value: `${trackingName} Selected`,
              href: link.href ?? link.to,
            });
            menuClickEvent({
              itemText: `${trackingName} Selected`,
              url: link.href
                ? link.href === HOTELS_URL
                  ? link.href
                  : `${HOTELS_URL}${link.href}`
                : link.to
                  ? link.to === HOTELS_URL
                    ? link.to
                    : `${HOTELS_URL}${link.to}`
                  : '',
            });
          }}
          {...link}
        />
      ))}
    </>
  );
});

interface PhoneMenuProps {
  qffLoginEnabled?: boolean;
  universalNavEnabled?: boolean;
}

export const PhoneMenu: FC<PhoneMenuProps> = ({ qffLoginEnabled = true, universalNavEnabled = true }: PhoneMenuProps) => {
  const { phoneLinks, isNavMenuOpen, toggleNavMenu, onInteraction } = useGlobalHeaderContext();
  const menuState = isNavMenuOpen ? 'Closed Menu' : 'Opened Menu';
  const [url, setUrl] = useState(HOTELS_URL);
  const { menuClickEvent } = useMenuClickEvent();

  useEffect(() => {
    if (isNavMenuOpen) {
      fixBodyScroll();
    } else {
      unfixBodyScroll();
    }

    return () => unfixBodyScroll();
  }, [isNavMenuOpen]);

  const handleNavInteraction = (interaction: InteractionInfo) => {
    onInteraction(interaction);
    toggleNavMenu();
    setUrl((!isEmpty(interaction.href) || interaction.href === undefined ? interaction.href : '') || '');
  };

  const handleToggleNavMenu = () => {
    onInteraction({
      type: 'Mobile Menu',
      value: isNavMenuOpen ? 'Menu Collapsed' : 'Menu Expanded',
    });
    menuClickEvent({
      itemText: `Phone Header ${menuState}`,
      url: url === HOTELS_URL ? url : `${HOTELS_URL}${url}`,
    });
    toggleNavMenu();
  };

  return (
    <Fragment>
      <MenuBtn role="button" isOpen={isNavMenuOpen} onClick={handleToggleNavMenu} data-testid="menu-button">
        <Text fontSize="header.base">Menu</Text>
      </MenuBtn>

      <Drawer isOpen={isNavMenuOpen}>
        {qffLoginEnabled && (
          <Box p={4} borderColor="white" borderBottom={1}>
            <QffAuthDetails />
          </Box>
        )}

        <PhoneLinks phoneLinks={phoneLinks} onInteraction={handleNavInteraction} />

        {universalNavEnabled && <QantasUniversalNav variant="mobile" />}
      </Drawer>

      <DrawerClose data-testid="close-btn-wrapper" isOpen={isNavMenuOpen}>
        <NakedButton role="button" onClick={handleToggleNavMenu} data-testid="close-btn">
          <Icon name="close" mt={7} />
        </NakedButton>
      </DrawerClose>
    </Fragment>
  );
};
