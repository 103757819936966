import React from 'react';
import styled from '@emotion/styled';
import { Flex, Link } from '@qga/roo-ui/components';
import { useGlobalHeaderContext } from '../../GlobalHeaderContext';
import { themeGet } from 'styled-system';

const StyledLink = styled(Link)`
  color: ${themeGet('colors.greys.charcoal')};
  padding-left: ${themeGet('space.5')};
  margin-bottom: ${themeGet('space.2')};
`;

export const QffLinks = () => {
  const { qffLinks, onInteraction } = useGlobalHeaderContext();
  return (
    <Flex py={5} flexDirection="column">
      {qffLinks.map(({ trackingName, ...link }) => (
        <StyledLink
          key={trackingName}
          onClick={() =>
            onInteraction({
              type: 'QFF Login Dropdown',
              value: `${trackingName} Selected`,
            })
          }
          {...link}
        />
      ))}
    </Flex>
  );
};
