import { createReducer } from '@reduxjs/toolkit';
import { PROMO_AREA_STATES } from 'lib/enums/search';
import {
  clearDeals,
  fetchDeals,
  fetchDealsSuccess,
  fetchDealsFailure,
  setLoading,
  setComponentState,
  setActiveCategory,
} from './promoAreaActions';

const initialState = {
  activeCategory: {},
  componentState: PROMO_AREA_STATES.EXPANDED,
  deals: [],
  error: null,
  isLoading: false,
  meta: {},
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(clearDeals, (state) => {
      state.deals = [];
      state.meta = {};
    })
    .addCase(fetchDeals, (state) => {
      state.error = null;
    })
    .addCase(fetchDealsFailure, (state, { payload }) => {
      state.deals = [];
      state.error = payload;
      state.meta = {};
    })
    .addCase(fetchDealsSuccess, (state, { payload }) => {
      state.deals = payload.deals;
      state.meta = payload.meta;
    })
    .addCase(setActiveCategory, (state, { payload }) => {
      state.activeCategory = payload;
    })
    .addCase(setComponentState, (state, { payload }) => {
      state.componentState = payload;
    })
    .addCase(setLoading, (state, { payload }) => {
      state.isLoading = payload;
    }),
);
