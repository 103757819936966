globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"0b408dd8465af5ec1f4dccb6f102244c92e9fa0b"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "/qantashotelsui";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate: 1.0,
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'ui.click') {
        if (hint?.event.target.dataTestid) {
          breadcrumb.message = hint?.event.target.dataTestid;
        } else if (hint?.event.target.ariaLabel) {
          breadcrumb.message = hint?.event.target.ariaLabel;
        } else {
          breadcrumb.message = hint?.event.target.class;
        }
      }
      return breadcrumb;
    },
    ignoreErrors: ["Failed to execute 'importScripts'"],
    denyUrls: [/\/widgets\/universal-nav\//, /\/universal-navigation-widget\//],
  });
}
