import { theme as baseTheme } from '@qga/roo-ui';
import merge from 'lodash/merge';

export const colors = merge({}, baseTheme.colors, {
  snow: '#fbfbfb',
  semiTransparentDark: 'rgba(0, 0, 0, 0.9)',
  external: {
    trivago: '#37454d',
    tripAdvisor: '#00a680',
    google: '#4885ed',
  },
  gradients: {
    transparentToGrey: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(244,245,246, 1) 90%)',
    transparentToWhite: 'linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 90%)',
  },
  ui: {
    linkFocus: baseTheme.colors.blue,
    iconBadge: baseTheme.colors.greys.charcoal,
    iconBadgeBackground: baseTheme.colors.greys.porcelain,
    qffLogin: baseTheme.colors.white,
    qffLoginBackground: baseTheme.colors.red,
  },
});
