import stringifyQueryValues from 'lib/search/stringifyQueryValues';
import { isDuplicateSearch, validSearches, setZeroTime } from './localStorage.helpers';

const replacePayload = (localStoragePayloads) => {
  localStorage.removeItem('savedPayload');
  localStorage.setItem('savedPayload', JSON.stringify(localStoragePayloads));
};

export const updateSearchLocalStorage = (payload) => {
  if (typeof window !== 'undefined') {
    const localStoragePayloads = JSON.parse(localStorage.getItem('savedPayload')) ?? [];

    localStoragePayloads.forEach((localStoragePayload, index) => {
      if (isDuplicateSearch(localStoragePayload, payload)) {
        localStoragePayloads.splice(index, 1);
      }
    });

    const { adults, children, infants, checkIn, checkOut, location, payWith, id, isPropertySearch } = payload;
    const searchListUrl = `/hotels/search/list?${stringifyQueryValues({
      adults,
      children,
      infants,
      checkIn,
      checkOut,
      location,
      payWith,
    })}`;
    const propertyUrl = `/hotels/properties/${id}?${stringifyQueryValues({ adults, children, infants, checkIn, checkOut, payWith })}`;
    const recentSearchUrl = isPropertySearch ? propertyUrl : searchListUrl;

    const newPayload = {
      ...payload,
      checkIn: setZeroTime(payload.checkIn),
      checkOut: setZeroTime(payload.checkOut),
      searchUrl: recentSearchUrl,
    };

    if (localStoragePayloads.length >= 4) {
      localStoragePayloads.shift();
      localStoragePayloads.push(newPayload);
    } else {
      localStoragePayloads.push(newPayload);
    }

    replacePayload(localStoragePayloads);
  }
};

export const getSearchLocalStorage = () => {
  if (typeof window !== 'undefined') {
    const localStoragePayloads = JSON.parse(localStorage.getItem('savedPayload')) || [];
    const sanitizedPayload = validSearches(localStoragePayloads);
    const currentSearchesReversed = sanitizedPayload.reverse();

    return currentSearchesReversed;
  }
};

export const clearSearchLocalStorage = () => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem('savedPayload');
  }
};
