import { colors } from './colors';
import { fontSizes } from './fontSizes';
import { theme as baseTheme } from '@qga/roo-ui';

export const fontFamily = 'Ciutadella, sans-serif';

export const textStyles = {
  h1: {
    fontSize: fontSizes.xl,
  },
  h3: {
    fontWeight: baseTheme.fontWeights.normal,
  },
  currency: {},
  link: {
    ...baseTheme.textStyles.text,
    color: colors.ui.link,
    textDecoration: 'underline',
    '&:hover': {
      color: colors.ui.linkHover,
    },
    '&:disabled': {
      color: colors.greys.steel,
    },
  },
  greyLink: {
    ...baseTheme.textStyles.text,
    color: colors.greys.charcoal,
    textDecoration: 'underline',
    '&:hover': {
      color: colors.ui.linkHover,
    },
    '&:disabled': {
      color: colors.greys.steel,
    },
  },
  button: {
    color: colors.ui.link,
    fontWeight: baseTheme.fontWeights.bold,
    textTransform: 'uppercase',
    textDecoration: 'none',
    '&:hover': {
      color: colors.ui.linkHover,
    },
    '&:disabled': {
      color: colors.greys.steel,
    },
  },
  cancel: {
    ...baseTheme.textStyles.text,
    color: colors.greys.steel,
    textDecoration: 'none',
    '&:hover': {
      color: colors.ui.linkHover,
    },
    '&:disabled': {
      color: colors.greys.steel,
    },
  },
  viewMoreLink: {
    textDecoration: 'underline',
  },
  modalLabel: {
    fontWeight: baseTheme.fontWeights.bold,
  },
};
