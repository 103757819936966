import { createReducer } from '@reduxjs/toolkit';
import {
  fetchPropertyAvailabilitySuccess,
  fetchPropertyAvailabilityFailure,
  setLoading,
  fetchPropertyAvailability,
  clearResults,
} from './propertyAvailabilityActions';

const initialState = {
  roomTypes: [],
  isLoading: false,
  error: null,
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(fetchPropertyAvailability, (state) => {
      state.error = null;
      state.isLoading = true;
    })
    .addCase(fetchPropertyAvailabilitySuccess, (state, { payload }) => {
      state.meta = payload.meta;
      state.roomTypes = payload.roomTypes;
    })
    .addCase(fetchPropertyAvailabilityFailure, (state, { payload }) => {
      state.error = payload;
      state.meta = {};
      state.roomTypes = [];
    })
    .addCase(clearResults, (state) => {
      state.meta = {};
      state.roomTypes = [];
    })
    .addCase(setLoading, (state, { payload }) => {
      state.isLoading = payload;
    }),
);
