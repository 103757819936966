import { compact } from 'lodash';

const addCommas = (number: string) => number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatNumber = (number: number | string, decimalPlaces?: number) => {
  const numberAsString = decimalPlaces === undefined ? number.toString() : Number(number).toFixed(decimalPlaces);
  const parts = numberAsString.split('.');
  return compact([addCommas(parts[0]), parts[1]]).join('.');
};

interface Name {
  title: string;
  firstName: string;
  lastName: string;
}

export const formatName = ({ title, firstName, lastName }: Name) => `${title} ${firstName} ${lastName}`;
