import * as client from 'lib/clients/createQuote';
import * as actions from 'store/quote/quoteActions';
import { setPointsLevels } from 'store/pointsConversion/pointsConversionActions';
import { getQuoteQuery } from 'store/quote/quoteSelectors';
import { getAccessToken, getFlightBookerToken, getQhUserId } from 'store/user/userSelectors';
import { createAsyncLogic } from 'lib/logic';

export const createQuoteLogic = createAsyncLogic({
  type: actions.createQuote,
  latest: true,
  async process({ getState }, dispatch) {
    dispatch(actions.setLoading(true));
    const state = getState();
    const payload = getQuoteQuery(state);
    const accessToken = getAccessToken(state);
    const flightBookerToken = getFlightBookerToken(state);
    const qhUserId = getQhUserId(state);
    const paidByDeposit = false;
    const { quote, meta } = await client.createQuote({ payload, accessToken, flightBookerToken, qhUserId, paidByDeposit });

    // It's important that the levels are set before the quote
    // so that usePointsAmountToAllocate, which depends on the levels being available
    // on initialization has the correct levels for conversion.
    // That logic is only allowed to execute once so if it triggers with the
    // wrong values, bad things happen.
    // TODO: consider a better approach this.
    const activeTierInstance = meta?.pointsTierInstance;
    if (activeTierInstance?.levels && activeTierInstance?.name) {
      dispatch(setPointsLevels(activeTierInstance));
    }
    dispatch(actions.createQuoteSuccess({ quote }));
  },
  onError({ parsedError }, dispatch) {
    dispatch(actions.createQuoteFailure(parsedError));
  },
  onFinally(context, dispatch) {
    dispatch(actions.setLoading(false));
  },
});
