import get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';

export const getResults = (state) => get(state, 'search.results');

export const getIsLoading = (state) => !!get(state, 'search.isLoading');

export const getHasError = (state) => !!get(state, 'search.error');

export const getSearchMeta = (state) => state?.search?.meta;

export const getSearchResults = (state) => state?.search?.results;

export const getResultCount = createSelector(getSearchMeta, (meta) => meta?.counts?.filtered || 0);

export const getTotalPropertyCount = createSelector(getSearchMeta, (meta) => meta?.counts?.total || 0);

export const getMinAvailableCashPrice = createSelector(getSearchMeta, (meta) => meta?.avgNightlyRates?.min || 0);

export const getMaxAvailableCashPrice = createSelector(getSearchMeta, (meta) => meta?.avgNightlyRates?.max || 0);

export const getMinAvailablePointsPrice = createSelector(getSearchMeta, (meta) => meta?.avgNightlyPoints?.min || 0);

export const getMaxAvailablePointsPrice = createSelector(getSearchMeta, (meta) => meta?.avgNightlyPoints?.max || 0);

export const getResultsRegionCenter = createSelector(getSearchMeta, (meta) => meta?.center);

export const getAvailableClassicOffersCount = createSelector(getSearchMeta, (meta) => meta?.counts?.byClassic || 0);

export const getAvailableDepositPayCount = createSelector(getSearchMeta, (meta) => meta?.counts?.byDepositPayable || 0);

export const getAvailableFreeCancellationCount = createSelector(getSearchMeta, (meta) => meta?.counts?.byRefundable || 0);

export const getAvailableLuxuryOffersCount = createSelector(getSearchMeta, (meta) => meta?.counts?.byLuxOffer || 0);

export const getSearchType = (state) => {
  const path = get(state, 'router.location.pathname');
  switch (path) {
    case '/search/list':
      return 'list';
    case '/search/map':
      return 'map';
    default:
      return undefined;
  }
};
