import { PUBLIC_PATH } from './env';
import { qantasHotelsLight } from '@qga/roo-ui/logos';

export const FAVICON_HREF = `${PUBLIC_PATH}qantas-favicon.ico`;
export const CONTACT_US = '/contact-us';
export const DESTINATIONS_AUSTRALIA = '/australia';
export const QH_SELF_SERVICE_URL = `/manage/bookings`;
export const TERMS_AND_CONDITIONS = `/terms-and-conditions`;
export const PRIVACY_AND_SECURITY_URL = 'https://www.qantas.com/au/en/support/privacy-and-security.html';
export const TERMS_AND_CONDITIONS_URL = 'https://www.qantas.com/hotels/terms-and-conditions'; // temporary redirection to old hotels site, until new page has been set up
export const TERMS_OF_USE_URL = 'https://www.qantas.com/au/en/support/terms-of-use.html';
export const ABN = 'Qantas Airways Limited ABN 16 009 661 901';
export const FOOTER_LOGO = qantasHotelsLight;
export const FOOTER_LOGO_LABEL = 'one world logo';
export const DOMESTIC_PHONE_NUMBER = '1300 738 206';
export const INTERNATIONAL_PHONE_NUMBER = '+61 2 8059 0182';
export const REQUEST_CALLBACK_PHONE_NUMBER = '13 70 66';

export const BRAND_NAME = 'Qantas';
export const HOTELS_BRAND_NAME = 'Qantas Hotels';
export const HOTELS_COMPANY_NAME = 'Qantas Group Accommodation Pty Ltd';
export const HOLIDAYS_BRAND_NAME = 'Qantas Holidays';

export const BRAND_CODE = 'qantas';
export const HOTELS_BRAND_BOOKING_CHANNEL = 'qantas_hotels_website';
export const LUXE_BRAND_BOOKING_CHANNEL = 'qantas_luxe_website';
export const TRACKING_PREFIX = 'QH';
