import { createReducer } from '@reduxjs/toolkit';
import { setPropertyExclusiveOffersPage } from './propertyExclusiveOffersPageActions';
import { PropertyExclusiveOffersPage } from 'types/content';

const initialState: { propertyExclusiveOffersPage: PropertyExclusiveOffersPage | null } = {
  propertyExclusiveOffersPage: null,
};

export const reducer = createReducer(initialState, (builder) =>
  builder.addCase(setPropertyExclusiveOffersPage, (state, { payload }) => {
    state.propertyExclusiveOffersPage = payload;
  }),
);
