import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { getCommonDataLayerVariables } from 'lib/datalayer/selectors/getCommonDataLayerVariables';
import { getPropertyPageDataLayerVariables } from 'lib/datalayer/selectors/getPropertyPageDataLayerVariables';
import { getCheckoutPageDataLayerVariables } from 'lib/datalayer/selectors/getCheckoutPageDataLayerVariables';
import { getConfirmationPageDataLayerVariables } from 'lib/datalayer/selectors/getConfirmationPageDataLayerVariables';

const noopSelector = () => ({});

const routeVariables = [
  {
    page: 'property',
    regex: /^\/properties\/(.*)/,
    variables: getPropertyPageDataLayerVariables,
  },
  {
    page: 'exclusive-offer',
    regex: /properties\/[^]+\/exclusive-offers/,
    variables: getPropertyPageDataLayerVariables,
  },
  {
    page: 'checkout',
    regex: /^\/checkout(.*)/,
    variables: getCheckoutPageDataLayerVariables,
  },
  {
    page: 'booking-confirmation',
    regex: /^\/bookings\/(.*)/,
    variables: getConfirmationPageDataLayerVariables,
  },
];

export const useRouteData = () => {
  const router = useRouter();

  const pageData = routeVariables.find(({ regex }) => router?.pathname?.match(regex));
  const routeSelector = pageData?.variables || noopSelector;

  const commonVariables = useSelector(getCommonDataLayerVariables);
  const pageVariables = useSelector(routeSelector);

  return {
    ready: true,
    ...commonVariables,
    ...pageVariables,
  };
};
