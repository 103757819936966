import wrappedAxios from './wrappedAxios';
import { HOTELS_API_URL } from 'config';

export const fetchPointsBurnLuxeTiers = async () => {
  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/points-burn-luxe`,
    method: 'GET',
    timeout: 20000,
  });
  return response.data.pointsTierInstance;
};
