import { filterUpdate } from 'store/search/searchActions';
import { EventType, createEvent } from '@qantasexperiences/analytics';

const emitFilterUpdateEvent = ({ payload }) => {
  return createEvent({
    type: EventType.FILTER_UPDATE,
    payload: {
      groupName: payload.groupName,
      itemName: payload.itemName,
      itemText: payload.itemText,
      itemType: payload.itemType,
      itemValue: payload.itemValue,
    },
  });
};

// eslint-disable-next-line
// @ts-ignore
export default { [filterUpdate]: emitFilterUpdateEvent };
