import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { rem } from 'polished';
import { Box, Link, Flex, NakedButton } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';

const drawOpenCSS = css`
  opacity: 1;
  transform: translateX(0);
`;

const drawClosedCSS = css`
  opacity: 0;
  transform: translateX(-100%);
`;

export const Drawer = styled(Box)<{ isOpen: boolean }>`
  overflow: scroll;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  transition: all 0.2s ease-in-out;
  color: ${themeGet('colors.white')};
  z-index: ${themeGet('zIndices.phoneMenuDrawer')};
  right: ${themeGet('uiStructure.phoneMenuDrawerCloseWidth')};
  background-color: ${themeGet('colors.greys.charcoal')};
  will-change: transform;
  ${({ isOpen }) => (isOpen ? drawOpenCSS : drawClosedCSS)}
`;

export const DrawerClose = styled(Flex)<{ isOpen: boolean }>`
  justify-content: flex-start;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  color: ${themeGet('colors.white')};
  width: ${themeGet('uiStructure.phoneMenuDrawerCloseWidth')};
  background-color: ${themeGet('colors.brand.primary')};
  z-index: ${themeGet('zIndices.phoneMenuDrawer')};
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
`;

const menuBtnOpenStyle = css`
  opacity: 0;
`;

const menuBtnClosedStyle = css`
  opacity: 1;
`;

export const MenuBtn = styled(NakedButton)<{ isOpen: boolean }>`
  cursor: pointer;
  width: ${themeGet('uiStructure.phoneMenuDrawerCloseWidth')};
  margin-right: ${themeGet('space.1')};
  padding: ${rem('22px')} 0;
  ${({ isOpen }) => (isOpen ? menuBtnOpenStyle : menuBtnClosedStyle)}
`;

export const PhoneMenuLink = styled(Link)`
  font-size: ${themeGet('fontSizes.header.base')};
  display: block;
  padding: 1rem;
  color: ${themeGet('colors.white')};
  border-bottom: 1px solid ${themeGet('colors.white')};
  &:hover {
    color: ${themeGet('colors.white')};
  }
`;
