import { createSelector } from '@reduxjs/toolkit';
import {
  getQueryAdults,
  getQueryCheckIn,
  getQueryCheckOut,
  getQueryChildren,
  getQueryInfants,
  getQueryOfferId,
  getQueryPropertyId,
  getQueryRoomTypeId,
} from 'store/router/routerSelectors';

export const getQuoteDepositPayQuery = createSelector(
  getQueryCheckIn,
  getQueryCheckOut,
  getQueryAdults,
  getQueryChildren,
  getQueryInfants,
  getQueryRoomTypeId,
  getQueryPropertyId,
  getQueryOfferId,
  (checkIn, checkOut, adults, children, infants, roomTypeId, propertyId, offerId) => ({
    adults,
    children,
    infants,
    checkIn,
    checkOut,
    roomTypeId,
    propertyId,
    offerId,
  }),
);
